import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import BookmarkClient from "../../api/bookmark/BookmarkClient";
import BookmarkDto from "../../api/bookmark/BookmarkDto";
import Bookmark from "../../model/bookmark/Bookmark";
import UpsertBookmarkCommand from "../../api/bookmark/data/UpsertBookmarkCommand";


class BookmarkStore {
    @observable
    private all: Array<Bookmark>;

    private client: BookmarkClient;

    constructor(client: BookmarkClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<Bookmark>();
    }

    fetchAll(): AxiosPromise {
        return this.client.getAll().then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<BookmarkDto>): void {
        const result = new Array<Bookmark>();

        value.map(dto => {
            result.push(Bookmark.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<Bookmark> {
        return this.all;
    }

    saveNewBookmark(command: UpsertBookmarkCommand): Promise<any> {
        return this.client.saveNewBookmark(command);
    }

    deactivateBookmark(command: UpsertBookmarkCommand): Promise<any> {
        return this.client.deactivateBookmark(command);
    }
}

export default BookmarkStore;