import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import {inject, observer} from "mobx-react";


const BookmarkForm = inject("bookmarkUiService")(observer(({bookmarkUiService, bookmark}) => {

    return (
        <Form className="bookmarkForm">
            <Form.Row>
                <Col>
                    <InputGroup>
                        <Form.Control
                            placeholder="http://www.example.com"
                            onChange={(e: any) => bookmark.handleLinkChanged(e.target.value)}
                            value={bookmark.getLink() !== null ? bookmark.getLink() : ""}
                        />
                        <InputGroup.Append>
                            <Button type="submit" variant="primary" onClick={() => bookmarkUiService.saveForm(bookmark)}>
                                <i className="fas fa-save"/>
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Row>
            <Form.Row>
                <Col>
                    <Form.Control
                        placeholder="Optionale Beschreibung"
                        onChange={(e: any) => bookmark.handleDescriptionChanged(e.target.value)}
                        value={bookmark.getDescription() !== null ? bookmark.getDescription() : ""}/>
                </Col>
            </Form.Row>
        </Form>
    );

}));

export default BookmarkForm;