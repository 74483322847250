import SnippetDto from "../../api/snippet/SnippetDto";
import Tag from "./Tag";

class Snippet {
    private id: number;
    private description: string;
    private snippet: string;
    private language: string;
    private tags: Array<Tag>;

    constructor(id: number, description: string, snippet: string, language: string, tags: Array<Tag>) {
        this.id = id;
        this.description = description;
        this.snippet = snippet;
        this.language = language;
        this.tags = tags;
    }

    getId(): number {
        return this.id;
    }

    getDescription(): string {
        return this.description;
    }

    getSnippet(): string {
        return this.snippet;
    }

    getLanguage(): string {
        return this.language;
    }

    getTags(): Array<Tag> {
        return this.tags;
    }

    static createFrom(dto: SnippetDto): Snippet {
        return new Snippet(dto.id, dto.description, dto.snippet, dto.language, dto.tags)
    }
}

export default Snippet;