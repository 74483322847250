import NotificationUiService from "../notification/NotificationUiService";
import BookmarkStore from "../../store/bookmark/BookmarkStore";
import Bookmark from "../../model/bookmark/Bookmark";
import UpsertBookmark from "../../model/bookmark/UpsertBookmark";
import UpsertBookmarkDto from "../../api/bookmark/UpsertBookmarkDto";
import UpsertBookmarkCommand from "../../api/bookmark/data/UpsertBookmarkCommand";

class BookmarkUiService {
    private bookmarkStore: BookmarkStore;

    constructor(bookmarkStore: BookmarkStore) {
        this.bookmarkStore = bookmarkStore;
    }

    loadAll(): void {
        this.bookmarkStore.fetchAll().catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Lesezeichen konnten nicht geladen werden!");
        });
    }

    getAll(): Array<Bookmark> {
        return this.bookmarkStore.getAll();
    }

    saveForm(bookmark: UpsertBookmark): void {
        const upsertBookmarkDto = new UpsertBookmarkDto(null, bookmark.getLink(), bookmark.getDescription());
        const command = new UpsertBookmarkCommand(upsertBookmarkDto);
        this.bookmarkStore.saveNewBookmark(command).then(response => {
            bookmark.clear();
            this.loadAll();
        }).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Lesezeichen konnte nicht gespeichert werden!");
        });
    }

    deactivateBookmark(id: number): void {
        const upsertBookmarkDto = new UpsertBookmarkDto(id, null, null);
        const command = new UpsertBookmarkCommand(upsertBookmarkDto);
        this.bookmarkStore.deactivateBookmark(command).then(response => {
            this.loadAll();
        }).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Lesezeichen konnte nicht gespeichert werden!");
        });
    }


}

export default BookmarkUiService;