import EventCollectionDto from "../../api/guildwars/EventCollectionDto";
import Gw2Event from "./Gw2Event";

class Gw2EventCollection {
    private location: string;
    private events: Array<Gw2Event>;

    constructor(location: string, events: Array<Gw2Event>) {
        this.location = location;
        this.events = events
    }

    getLocation(): string {
        return this.location;
    }

    getEvents(): Array<Gw2Event> {
        return this.events;
    }

    static createFrom(dto: EventCollectionDto): Gw2EventCollection {
        return new Gw2EventCollection(dto.location, dto.events)
    }
}

export default Gw2EventCollection;