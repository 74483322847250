import axios, {AxiosPromise} from "axios";
import NetworkDeviceDto from "./NetworkDeviceDto";

class NetworkDeviceClient {
    private readonly _baseUrl: string;
    private readonly _homeUrl: string;

    constructor(baseUrl: string, homeUrl: string) {
        this._baseUrl = baseUrl;
        this._homeUrl = homeUrl;
    }

    getAll(): AxiosPromise {
        const url = `${this._baseUrl}/intern/network-device/get/states/`;
        return axios.get<Array<NetworkDeviceDto>>(url);
    }

    toggleStatus(ip: string, mac: string, status: string): AxiosPromise {
        const url = `${this._baseUrl}/intern/network-device/`;
        return axios.post(url, {"ip": ip, "mac": mac, "status": status})
            .then(response => this.getAll());
    }
}

export default NetworkDeviceClient;