import React, {useEffect, useState, useRef} from "react";
import {Row, Col} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import moment from "moment-timezone";
import EventRow from "./EventRow";



const Gw2EventContainer = inject("guildwarsUiService")(observer(({guildwarsUiService}) => {

    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                guildwarsUiService.loadAll();
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });

            const setPosition = () => {
                scrollToRef(myRef, getCalculatedOffset())
            };

            const scrollToRef = (myRef, offset) => {
                setOffset(getCalculatedOffset());
                myRef.current.scrollTo(offset, 0);

            };

            const interval = setInterval(() => setPosition(), 5000);
            return () => clearInterval(interval);
        },
        [
            guildwarsUiService
        ]);


    const myRef = useRef(null);

    const getCalculatedOffset = () => {
        const mmt = moment.utc();
        const mmtMidnight = mmt.clone().startOf('day');
        var diffMinutes = mmt.diff(mmtMidnight, 'minutes');



        if(diffMinutes < 120){
            return (diffMinutes + 1440 - 20) * 5;
        }
        return (diffMinutes - 20) * 5;
    };

    const [offset, setOffset] = useState(getCalculatedOffset());

    return (
        <div className="eventWrapper" ref={myRef}>
            <Row>
                <Col className="passedEvents" style={{marginLeft: offset}}>
                    {guildwarsUiService.getAll().map((location,key) => {
                        return (<div className="eventLocation" key={key}>{location.getLocation()}</div>);
                    })}
                </Col>
            </Row>
            <Row className="eventContent">
                {guildwarsUiService.getAll().map((location,key) => {
                    return (<EventRow key={key} events={location.getEvents()} location={location.getLocation()}>{location.getLocation()}</EventRow>);
                })}
            </Row>
        </div>
    );
}));

export default Gw2EventContainer;

