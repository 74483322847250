import React from "react";
import {Col, Row} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import PipDivision from "../../model/guildwars/PipDivision";
import moment from "moment";
import wood from "../../assets/Holz.png";
import bronze from "../../assets/Bronze.png";
import silver from "../../assets/Silber.png";
import gold from "../../assets/Gold.png";
import platinum from "../../assets/Platin.png";
import mithril from "../../assets/Mithril.png";
import diamond from "../../assets/Diamant.png";

const Gw2PipCalculator = inject("guildwarsUiService")(observer(({guildwarsUiService}) => {

    const divisions = [
        new PipDivision("wood", "Holz", 4, 25, 0, wood),
        new PipDivision("bronze", "Bronze", 4, 30, 100, bronze),
        new PipDivision("silver", "Silber", 5, 35, 220, silver),
        new PipDivision("gold", "Gold", 5, 40, 395, gold),
        new PipDivision("platinum", "Platin", 5, 45, 595, platinum),
        new PipDivision("mithril", "Mithril", 6, 50, 820, mithril),
        new PipDivision("diamond", "Diamant", 6, 55, 1120, diamond)
    ];

    const pipsPerTick = [6, 7, 8, 9, 10, 11, 12, 13, 14];

    const selectedDivision = guildwarsUiService.getSelectedPipDevision();
    const calculatedStats = guildwarsUiService.getCalculatedStats();


    const getRankCount = (rankCount) => {
        const ranks = [];
        for (let i = 1; i <= rankCount; i++) {
            ranks.push(i)
        }
        return ranks;
    };

    const getRankPointCount = (rankPointCount) => {
        const rankPoints = [];
        for (let i = 1; i <= rankPointCount; i++) {
            rankPoints.push(i)
        }
        return rankPoints;
    };

    return (
        <React.Fragment>


            <Row className="statRow">
                <Col onClick={() => guildwarsUiService.togglePipCalculation()}><img src={wood} className="pipToggle"
                                                                                    alt="toggle pips"/></Col>
                <Col>Pips verbleibend : {calculatedStats && calculatedStats.getRemainingPips()}</Col>
                <Col>Minuten bis Ende : {calculatedStats && calculatedStats.getRemainingMinutes()}</Col>
                <Col>Restliche
                    Zeit: {moment.utc(moment.duration(calculatedStats && calculatedStats.getRemainingHours(), 'h').asMilliseconds()).format('HH:mm')}</Col>
            </Row>
            <div className={guildwarsUiService.getPipCalculationShowStatus() ? "show" : "hide"}>

                <Row className="pipsPerTickRow">
                    {pipsPerTick.map(pips => {
                        return (
                            <Col key={pips}
                                 className={pips === guildwarsUiService.getPipsPerTick() ? "pipsPerTick selected" : "pipsPerTick"}
                                 onClick={() => guildwarsUiService.selectPipsPerTick(pips)}>
                                {pips}
                            </Col>
                        );
                    })}
                </Row>

                <Row className="divisionRow">
                    {divisions.map(division => {
                        return (
                            <Col key={division.getName()}
                                 className={selectedDivision && selectedDivision.getDivision() === division.getDivision() ? "division selected" : "division"}
                                 onClick={() => guildwarsUiService.selectPipDevision(division)}>
                                <img src={division.getIcon()} className="divisionIcon" alt={division.getName()}/><br/>
                                {division.getName()}
                            </Col>
                        );
                    })}
                </Row>

                <Row className="rankRow">
                    {selectedDivision && getRankCount(selectedDivision.getRanks()).map(rank => {
                        return (
                            <span key={rank} onClick={() => guildwarsUiService.selectPipDevisionRank(rank)}>
                            {rank <= guildwarsUiService.getSelectedPipDevisionRank() ?
                                <i className="fas fa-circle"/> :
                                <i className="far fa-circle"/>}
                        </span>
                        );
                    })}
                </Row>

                <Row className="pointRow">
                    {selectedDivision && getRankPointCount(selectedDivision.getPointsPerRank()).map(points => {
                        return (
                            <React.Fragment key={points}>
                                <div
    className={points <= guildwarsUiService.getSelectedPipDevisionRankPoints() ? "pip selected" : "pip"}
    onClick={() => guildwarsUiService.selectPipDevisionRankPoints(points)}/>
                                {points % 10 === 0 ? <span className="spacer"/> : null}
                            </React.Fragment>

                        );
                    })}
                </Row>
            </div>
        </React.Fragment>
    );
}));

export default Gw2PipCalculator;

