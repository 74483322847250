import SensorDto from "../../api/smartHome/SensorDto";
import Room from "./Room";

class Sensor {
    private id: string;
    private room: Room;
    private temperature: number;
    private humidity: number;
    private lastUpdated: string;

    constructor(id: string, room: Room, temperature: number, humidity: number, lastUpdated: string) {
        this.id = id;
        this.room = room;
        this.temperature = temperature;
        this.humidity = humidity;
        this.lastUpdated = lastUpdated;
    }

    getId(): string {
        return this.id;
    }

    getRoom(): Room {
        return this.room;
    }

    getTemperature(): number {
        return this.temperature;
    }

    getHumidity(): number {
        return this.humidity;
    }

    getLastUpdated(): string {
        return this.lastUpdated;
    }

    static createFrom(dto: SensorDto): Sensor {
        return new Sensor(dto.id, Room.createFrom(dto.room), dto.temperature, dto.humidity, dto.lastUpdated)
    }
}

export default Sensor;