import React from "react";
import {inject, observer} from "mobx-react";

const Sensor = inject("smartHomeUiService")(observer(({roomName, smartHomeUiService}) => {

    const sensors = smartHomeUiService.getSensors(roomName) ? smartHomeUiService.getSensors(roomName) : null;

    const light = smartHomeUiService.getLightByLightName(roomName) ? smartHomeUiService.getLightByLightName(roomName) : null;
    const colorByLightStatus = light && light.getOn() ? "black" : "white";

    const renderSensors = (sensors) => {
        if (!sensors || sensors.length === 0) {
            return <div/>
        }

        return (
            <div className="sensors" style={{color:colorByLightStatus}}>
                {sensors.map((sensor, key ) => {
                    return (
                        <div className={"sensor " + sensor.getRoom().getName() + key} key={sensor.getId()}>
                            {Number(sensor.getTemperature()).toFixed(1)}°C <br/>
                            <span>
                                {sensor.getHumidity() !== 999 ? Number(sensor.getHumidity()).toFixed(1) + " %" : null}
                            </span>
                        </div>
                    );
                })}
            </div>
        );

    };

    return renderSensors(sensors);

}));

export default Sensor;