import NotificationStore from "../../store/notification/NotificationStore";
import InfoNotification from "../../model/notification/InfoNotification";
import Notification from "../../model/notification/Notification";
import ErrorNotification from "../../model/notification/ErrorNotification";
import SuccessNotification from "../../model/notification/SuccessNotification";

class NotificationService {
    private notificationStore: NotificationStore;

    constructor(notificationStore: NotificationStore) {
        this.notificationStore = notificationStore;
    }

    get notifications(): Array<Notification> {
        return this.notificationStore.notifications;
    }

    showSuccessNotification(headline: string, message: string): void {
        this.notificationStore.showNotification(new SuccessNotification(headline, message));
    }

    showInfoNotification(headline: string, message: string): void {
        this.notificationStore.showNotification(new InfoNotification(headline, message));
    }

    showErrorNotification(headline: string, message: string): void {
        this.notificationStore.showNotification(new ErrorNotification(headline, message));
    }

    removeNotification(notification: Notification): void {
        this.notificationStore.removeNotification(notification);
    }
}

export default NotificationService;