import {action, observable} from "mobx";

class SmartHomeStore {

    @observable
    private activeView: string;

    constructor() {
        this.init();
    }

    @action
    private init(): void {
        this.activeView = "overview";
    }

    setActiveView(view: string): void {
        this.activeView = view;
    }

    getActiveView(): string {
        return this.activeView;
    }
}

export default SmartHomeStore;