import MeterReadingDto from "./MeterReadingDto";
import MeterReading from "../../model/meter/MeterReading";

class UpsertMeterReadingDto {
    id: number;
    readingDate: string;
    meterReading: number;
    meterReadingCorrection: number;
    meterId: string;

    constructor(id: number, readingDate: string, meterReading: number, meterReadingCorrection: number, meterId: string) {
        this.id = id;
        this.readingDate = readingDate;
        this.meterReading = meterReading;
        this.meterReadingCorrection = meterReadingCorrection;
        this.meterId = meterId;
    }

    static createFrom(meterReading: MeterReading): UpsertMeterReadingDto {
        return new UpsertMeterReadingDto(meterReading.id, meterReading.readingDate, meterReading.meterReading, meterReading.meterReadingCorrection, meterReading.meterId)
    }
}

export default UpsertMeterReadingDto;
