import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import ShoppingItemClient from "../../api/shopping/ShoppingItemClient";
import ShoppingItemDto from "../../api/shopping/ShoppingItemDto";
import ShoppingItem from "../../model/shopping/ShoppingItem";
import UpsertShoppingItemCommand from "../../api/shopping/data/UpsertShoppingItemCommand";


class ShoppingItemStore {
    @observable
    private all: Array<ShoppingItem>;

    @observable
    private showActiveItems: boolean;

    private client: ShoppingItemClient;

    constructor(client: ShoppingItemClient) {
        this.client = client;
        this.showActiveItems = true;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<ShoppingItem>();
    }

    fetchAll(activeItems: boolean): AxiosPromise {
        return this.client.getAll(activeItems).then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<ShoppingItemDto>): void {
        const result = new Array<ShoppingItem>();

        value.map(dto => {
            result.push(ShoppingItem.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<ShoppingItem> {
        return this.all;
    }

    getListView(): boolean {
        return this.showActiveItems;
    }

    @action
    changeListView(): void {
        this.showActiveItems = !this.showActiveItems;
        this.fetchAll(this.showActiveItems)
    }

    saveNewShoppingItem(command: UpsertShoppingItemCommand): Promise<any> {
        return this.client.saveNewShoppingItem(command);
    }

    deactivateShoppingItem(command: UpsertShoppingItemCommand): Promise<any> {
        return this.client.changeShoppingItemStatus(command);
    }
}

export default ShoppingItemStore;