import React from 'react';
import moment from 'moment'

class Clock extends React.Component {
    constructor(props) {
        super(props);
        var now  = moment().locale('de');
        this.state = {
            day: now.format('dddd'),
            date: now.format('DD.MM.YYYY'),
            time: now.format('HH:mm:ss')
        };
    }
    componentDidMount() {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );
    }
    componentWillUnmount() {
        clearInterval(this.intervalID);
    }
    tick() {
        var now  = moment().locale('de');
        this.setState({
            day: now.format('dddd'),
            date: now.format('DD.MM.YYYY'),
            time: now.format('HH:mm:ss')
        });
    }
    render() {
        return (
            <div className="row clock">
            <div className="col-12">
                <div id="date">{this.state.day}, {this.state.date}</div>
                <div id="clock" className="unit">{this.state.time}</div>
            </div>
            </div>
        );
    }
}

export default Clock;