import React from "react";
import {inject, observer} from "mobx-react";


const Device = inject("smartHomeUiService")(observer(({smartHomeUiService, deviceName, icon}) => {

    const device = smartHomeUiService.getNetworkDevice(deviceName) ? smartHomeUiService.getNetworkDevice(deviceName) : null;
    const status: string = device && device.getNewActive() === "1" ? "on" : "off";

    return (
        <div className={deviceName + " btn " + status}
             onClick={device ? () => smartHomeUiService.toggleNetworkDevice(device.getNewIPAddress(), device.getNewMACAddress(), device.getNewActive()) : null}>
            <i className={"fas fa-" + icon}/>
        </div>
    );

}));

export default Device;