class PipStats {
    private remainingPips: number;
    private remainingMinutes: number;
    private remainingHours: number;

    constructor(remainingPips: number, remainingMinutes: number, remainingHours: number) {
        this.remainingPips = remainingPips;
        this.remainingMinutes = remainingMinutes;
        this.remainingHours = remainingHours;
    }

    getRemainingPips(): number {
        return this.remainingPips;
    }

    getRemainingMinutes(): number {
        return this.remainingMinutes;
    }

    getRemainingHours(): number {
        return this.remainingHours;
    }
}

export default PipStats;
