import ClimateControlDto from "../../api/smartHome/ClimateControlDto";

class ClimateControl {
    private id: string;
    private room: string;
    private currentSet: number;
    private ecoSet: number;
    private comfortSet: number;
    private operationMode: string;
    // private lastUpdated: string;
    private boostActive: boolean;

    constructor(id: string, room: string, currentSet: number, ecoSet: number, comfortSet: number, operationMode: string, boostActive: boolean) {
        this.id = id;
        this.room = room;
        this.currentSet = currentSet;
        this.ecoSet = ecoSet;
        this.comfortSet = comfortSet;
        this.operationMode = operationMode;
        // this.lastUpdated = lastUpdated;
        this.boostActive = boostActive;
    }

    getId(): string {
        return this.id;
    }

    getRoom(): string {
        return this.room;
    }

    getCurrentSet(): number {
        return this.currentSet;
    }

    getEcoSet(): number {
        return this.ecoSet;
    }

    getComfortSet(): number {
        return this.comfortSet;
    }

    getOperationMode(): string {
        return this.operationMode;
    }

    // get getLastUpdated(): string {
    //     return this.lastUpdated;
    // }

    istBoostActive(): boolean {
        return this.boostActive;
    }

    static createFrom(dto: ClimateControlDto): ClimateControl {
        return new ClimateControl(dto.id, dto.room, dto.currentSet, dto.ecoSet, dto.comfortSet, dto.operationMode, dto.boostActive)
    }
}

export default ClimateControl;