import {action, observable} from "mobx";
import axios from "axios";
import _ from "lodash";
import jwt from "jsonwebtoken";
import ClientJS from "clientjs";
import AuthClient from "../../api/auth/AuthClient";

class UserSessionStore {
    @observable
    private authToken: string;

    @observable
    private username: string;

    @observable
    private refreshToken: string;

    @observable
    private roles: Array<string>;

    private authClient: AuthClient;

    private readonly client: any;

    constructor(authClient: AuthClient) {
        this.authClient = authClient;
        this.client = new ClientJS();
    }

    @action
    saveInSession(token: string, refreshToken: string): void {

        this.authToken = token;

        const decodedToken = jwt.decode(token);
        this.roles = new Array<string>((decodedToken as any)?.roles);
        this.username = (decodedToken as any)?.username;
        this.refreshToken = refreshToken;
        this.saveValueInLocalStorage("authToken", token);
        this.saveValueInLocalStorage("username", this.username);
        this.saveValueInLocalStorage("refreshToken", this.refreshToken);
        this.saveValueInLocalStorage("roles", [...this.roles].concat(","));
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.authToken;
    }

    hasAdminRole(): boolean {
        return _.includes(this.roles, "ROLE_ADMIN");
    }

    getUsername(): string {
        return this.username;
    }

    @action
    clear(): void {
        this.authToken = "";
        this.username = "";
        sessionStorage.clear();
        localStorage.clear();
        delete axios.defaults.headers.common["Auth-Token"];
    }

    isAuthenticated(): boolean {
        return !_.isEmpty(this.authToken) && this.authToken !== 'undefined';

    }

    @action
    public init(): void {
        this.username = this.getValueFromLocalStorage("username");
        this.authToken = this.getValueFromLocalStorage("authToken");
        this.roles = this.getValueFromLocalStorage("roles");
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.authToken;
    }

    public validateToken(): void {
        if (_.isEmpty(this.authToken)) {
            return;
        }
        this.authClient.validate(this.authToken)
            .catch(() => {
                this.clear();
            });
    }

    // private saveValueInSessionStorage(key: string, value: any): void {
    //     sessionStorage.setItem(key, value);
    // }

    private saveValueInLocalStorage(key: string, value: any): void {
        localStorage.setItem(key, value);
    }

    // private getValueFromSessionStorage(key: string): any {
    //     if (sessionStorage.getItem(key) === null) {
    //         return "";
    //     }
    //
    //     return sessionStorage.getItem(key);
    // }

    private getValueFromLocalStorage(key: string): any {
        if (localStorage.getItem(key) === null) {
            return "";
        }

        return localStorage.getItem(key);
    }
}

export default UserSessionStore;