import React, {useState} from "react";
import {inject, observer} from "mobx-react";
import TemperatureSlider from "./temperatureslider/TemperatureSlider";


const Detail = inject("smartHomeUiService")(observer(({smartHomeUiService}) => {

    const saveTemperature = (roomName, temperature) => {
        smartHomeUiService.setTemperature(roomName, temperature);
    }

    return (
        <div>
            <div className="row">
                <div className="col-12 col-md-6">
                    {smartHomeUiService.getClimateControlStates().map(climateControl => {
                        return (
                            <TemperatureSlider
                                ccState={climateControl}
                                saveTemperature={(a,b) => saveTemperature(a,b)}
                                key={climateControl.room}/>
                        );
                    })}
                </div>
            </div>
        </div>
    );


}));

export default Detail;