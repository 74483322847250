import SensorStore from "../../store/smartHome/SensorStore";
import NotificationUiService from "../notification/NotificationUiService";
import Sensor from "../../model/smartHome/Sensor";
import _ from "lodash";
import Light from "../../model/smartHome/Light";
import LightStore from "../../store/smartHome/LightStore";
import ClimateControlStore from "../../store/smartHome/ClimateControlStore";
import ClimateControl from "../../model/smartHome/ClimateControl";
import NetworkDeviceStore from "../../store/smartHome/NetworkDeviceStore";
import NetworkDevice from "../../model/smartHome/NetworkDevice";
import SmartHomeStore from "../../store/smartHome/SmartHomeStore";
import RoomStore from "../../store/smartHome/RoomStore";
import Room from "../../model/smartHome/Room";
import PlugStore from "../../store/smartHome/PlugStore";
import Plug from "../../model/smartHome/Plug";

class SmartHomeUiService {
    private roomStore: RoomStore;
    private smartHomeStore: SmartHomeStore;
    private sensorStore: SensorStore;
    private lightStore: LightStore;
    private plugStore: PlugStore;
    private climateControlStore: ClimateControlStore;
    private networkDeviceStore: NetworkDeviceStore;
    private activeView: string;

    constructor(
        roomStore: RoomStore,
        smartHomeStore: SmartHomeStore,
        sensorStore: SensorStore,
        lightStore: LightStore,
        plugStore: PlugStore,
        climateControlStore: ClimateControlStore,
        networkDeviceStore: NetworkDeviceStore
    ) {
        this.roomStore = roomStore;
        this.smartHomeStore = smartHomeStore;
        this.sensorStore = sensorStore;
        this.lightStore = lightStore;
        this.plugStore = plugStore;
        this.climateControlStore = climateControlStore;
        this.networkDeviceStore = networkDeviceStore;
        this.activeView = "overview"; //overview, detail, livestream
    }

    loadAll(): void {
        this.loadRooms();
        this.loadPlugs();
        this.loadLights()
        this.loadSensors()
        this.loadClimateControlState()
        this.loadNetworkDeviceState()
    }

    loadPlugs(): void {
        this.plugStore.fetchAll().catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Steckdosenwerte konnten nicht geladen werden!");
        });
    }

    loadSensors(): void {
        this.sensorStore.fetchAll().catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Sensorenwerte konnten nicht geladen werden!");
        });
    }

    loadLights(): void {
        this.lightStore.fetchAll().catch(() => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Lampenwerte konnten nicht geladen werden!");
        });
    }

    loadClimateControlState(): void {
        this.climateControlStore.fetchAll().catch(() => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Heizungswerte konnten nicht geladen werden!");
        });
    }

    loadNetworkDeviceState(): void {
        this.networkDeviceStore.fetchAll().catch(() => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Netzwerkgeräte konnten nicht geladen werden!");
        });
    }

    loadRooms(): void {
        this.roomStore.fetchAll().catch(() => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Räume konnten nicht geladen werden!");
        });
    }

    getRooms(): Array<Room> {
        return this.roomStore.getAll();
    }

    getLights(name: string): Array<Light> {
        return _.filter(this.lightStore.getAll(), function (o) {
            return o.getRoom() === name;
        });
    }

    getPlug(name: string): Plug {
        return _.filter(this.plugStore.getAll(), function (o) {
            return o.getName().toLowerCase() === name.toLowerCase();
        })[0];
    }

    getLightByLightName(name: string): Light {
        return _.filter(this.lightStore.getAll(), function (o) {
            return o.getName() === name;
        })[0];
    }

    getSensors(name: string): Array<Sensor> {
        return _.filter(this.sensorStore.getAll(), function (o) {
            return o.getRoom().getName() === name;
        });
    }

    getClimateControlState(name: string): ClimateControl {
        return _.filter(this.climateControlStore.getAll(), function (o) {
            return o.getRoom() === name;
        })[0];
    }

    getClimateControlStates(): Array<ClimateControl> {
        return this.climateControlStore.getAll()
    }

    getNetworkDevice(name: string): NetworkDevice {
        return _.filter(this.networkDeviceStore.getAll(), function (o) {
            return o.getNewHostName().toLowerCase() === name;
        })[0];
    }

    toggleLight(id: number, status: boolean): void {
        const newStatus = status ? "off" : "on"
        this.lightStore.toggleLight(id, newStatus);
    }

    togglePlug(id: number, status: boolean): void {
        const newStatus = status ? "off" : "on"
        this.plugStore.togglePlug(id, newStatus);
    }

    toggleNetworkDevice(ip: string, mac: string, status: boolean): void {
        const newStatus = status ? "off" : "on"
        this.networkDeviceStore.toggleStatus(ip, mac, newStatus)
            .then(() => {
                NotificationUiService.getInstance().showInfoNotification("Info:", "Das Signal wurde erfolgreich gesendet");
            });
    }

    changeActiveView(view: string): void {
        this.smartHomeStore.setActiveView(view)
    }

    getActiveView(): string {
        return this.smartHomeStore.getActiveView();
    }

    setTemperature(name: string, temperature: number): void {
        this.climateControlStore.setTemperature(name, temperature);
    }


}

export default SmartHomeUiService;