import VersionInfoStore from "../../store/versioninfo/VersionInfoStore";

class VersionInfoUiService {

    private store: VersionInfoStore;

    constructor(store: VersionInfoStore) {
        this.store = store;
    }

    getBackendVersion() {
        return this.store.getBackendVersion();
    }

    getFrontendVersion() {
        return this.store.getFrontendVersion();
    }

    fetchVersions() {
        this.store.fetchVersions();
    }

}

export default VersionInfoUiService;