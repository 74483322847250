import React from "react";
import {Container} from "react-bootstrap";
import UpsertShoppingItem from "../../model/shopping/UpsertShoppingItem";
import ShoppingItemForm from "./form/ShoppingItemForm";
import ShoppingItemList from "./list/ShoppingItemList";
import ShoppingItemListChanger from "./ShoppingItemListChanger";


const ShoppingIndex = () => {

    return (
        <Container fluid={true} className="shopping">
            <ShoppingItemForm shoppingItem={new UpsertShoppingItem()}/>
            <ShoppingItemList/>
            <ShoppingItemListChanger/>
        </Container>
    );
};

export default ShoppingIndex;