import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import LightDto from "../../api/smartHome/LightDto";
import LightClient from "../../api/smartHome/LightClient";
import Light from "../../model/smartHome/Light";

class LightStore {
    @observable
    private all: Array<Light>;

    private client: LightClient;

    constructor(client: LightClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<Light>();
    }

    fetchAll(): AxiosPromise {
        return this.client.getAll().then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<LightDto>): void {
        const result = new Array<Light>();

        value.map(dto => {
            result.push(Light.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<Light> {
        return this.all;
    }

    // toggleDevice(id: number): void {
    //     this.client.toggleDevice(id);
    // }

    toggleLight(id: number, status: string): AxiosPromise {
        return this.client.toggleLight(id, status).then(response => {
            this.setAll(response.data);
            return response;
        });
    }
}

export default LightStore;