import React from "react";
import {inject, observer} from "mobx-react";
import {Row, Col, Form} from "react-bootstrap";


const SnippetFilter = inject("snippetUiService")(observer(({snippetUiService, handleShow}) => {

    return (
        <Row>
            <Col xs="auto" onClick={handleShow}>neu</Col>
            <Col>
                <Form.Group controlId="formSnippet">
                    <Form.Control as="input"
                                  rows="5"
                                  onChange={(e: any) => snippetUiService.handleSnippetFilterChanged(e.target.value)}
                                  value={snippetUiService.getFilterValue() !== null ? snippetUiService.getFilterValue() : ""}
                    />
                </Form.Group>
            </Col>
        </Row>
    );
}));

export default SnippetFilter;