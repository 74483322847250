import NetworkDeviceDto from "../../api/smartHome/NetworkDeviceDto";

class NetworkDevice {
    private newIPAddress: string;
    private newAddressSource: string;
    private newLeaseTimeRemaining: string;
    private newMACAddress: string;
    private newInterfaceType: string;
    private newActive: string;
    private newHostName: string;


    constructor(
        newIPAddress: string,
        newAddressSource: string,
        newLeaseTimeRemaining: string,
        newMACAddress: string,
        newInterfaceType: string,
        newActive: string,
        newHostName: string
    ) {
        this.newIPAddress = newIPAddress;
        this.newAddressSource = newAddressSource;
        this.newLeaseTimeRemaining = newLeaseTimeRemaining;
        this.newMACAddress = newMACAddress;
        this.newInterfaceType = newInterfaceType;
        this.newActive = newActive;
        this.newHostName = newHostName;

    }

    getNewIPAddress(): string {
        return this.newIPAddress;
    }

    getNewAddressSource(): string {
        return this.newAddressSource;
    }

    getNewLeaseTimeRemaining(): string {
        return this.newLeaseTimeRemaining;
    }

    getNewMACAddress(): string {
        return this.newMACAddress;
    }

    getNewInterfaceType(): string {
        return this.newInterfaceType;
    }

    getNewActive(): string {
        return this.newActive;
    }

    getNewHostName(): string {
        return this.newHostName;
    }


    static createFrom(dto: NetworkDeviceDto): NetworkDevice {
        return new NetworkDevice(dto.NewIPAddress, dto.NewAddressSource, dto.NewLeaseTimeRemaining, dto.NewMACAddress, dto.NewInterfaceType, dto.NewActive, dto.NewHostName)
    }
}

export default NetworkDevice;