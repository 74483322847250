import React from "react";
import {Button, Card, Col, Container, FormControl, InputGroup, Row} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import {Redirect} from "react-router-dom";
import Routes from "../../api/Routes";

const Login = inject("loginUiService")(observer(({loginUiService}) => {
    if (loginUiService.isAuthenticated()) {
        return (
            <Redirect to={Routes.INDEX}/>
        );
    }

    return (
        <div className="login">
            <Container>
                <Row>
                    <Col>
                        <Card className="bg-dark">
                            <Card.Body>
                                <Card.Title>
                                    <h1 className="text-center loginHeadline">Out of memory</h1>
                                </Card.Title>
                                <InputGroup className="mb-3">
                                    <FormControl
                                        type={"text"}
                                        onChange={(e: any) => loginUiService.usernameChanged(e)}
                                        placeholder="Benutzername"
                                    />
                                </InputGroup>

                                <InputGroup className="mb-4">
                                    <FormControl
                                        onChange={(e: any) => loginUiService.passwordChanged(e)}
                                        onKeyPress={(e: any) => loginUiService.handleKeyPress(e)}
                                        type={"password"}
                                        placeholder="Passwort"
                                    />
                                </InputGroup>
                                <Button variant="primary" className="btn-block"
                                        onClick={() => loginUiService.loginButtonClicked()}>
                                    Login
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}));

export default Login;