import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import MeterReadingDto from "../../api/meter/MeterReadingDto";
import MeterClient from "../../api/meter/MeterClient";
import MeterReading from "../../model/meter/MeterReading";
import MeterTotalOverview from "../../model/meter/MeterTotalOverview";
import MeterTotalOverviewDto from "../../api/meter/MeterTotalOverviewReadingDto";

class MeterStore {
    @observable
    private all: Array<MeterReading>;

    @observable
    private overview: MeterTotalOverview;

    private client: MeterClient;

    @observable
    private activeView: string;

    @observable
    private activeStatisticView: string;

    constructor(client: MeterClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<MeterReading>();
        this.activeView = "statistic";
        this.activeStatisticView = "totalOverview";
    }

    fetchAll(date: string): AxiosPromise {
        return this.client.getAll(date).then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    fetchOverview(startDate: string, endDate: string): AxiosPromise {
        return this.client.getOverview(startDate, endDate).then(response => {
            this.setOverview(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<MeterReadingDto>): void {
        const result = new Array<MeterReading>();

        value.map(dto => {
            result.push(MeterReading.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    @action
    private setOverview(value: MeterTotalOverviewDto): void {
        this.overview = MeterTotalOverview.createFrom(value);
    }

    getAll(): Array<MeterReading> {
        return this.all;
    }

    getOverview(): MeterTotalOverview {
        return this.overview;
    }

    setActiveStatisticView(view: string): void {
        this.activeStatisticView = view;
    }

    getActiveStatisticView(): string {
        return this.activeStatisticView;
    }

    setActiveView(view: string): void {
        this.activeView = view;
    }

    getActiveView(): string {
        return this.activeView;
    }

    @action
    update(readings: Array<MeterReading>): void {
        this.all = new Array<MeterReading>();
        this.all = readings;
    }

    saveReadings(command): AxiosPromise{
        return this.client.saveReadings(command).then(response => {
            return response;
        })
    }
}

export default MeterStore;