import React from "react";
import Routes from "../../api/Routes";
import NavigationItem from "./NavigationItem";

const Navigation = () => {
    return (
        <nav className="navigation bg-light">
            <div className="row">
                <NavigationItem target={`${Routes.INDEX}`} icon={"fas fa-home"}/>
                <NavigationItem target={`${Routes.SMARTHOME}`} icon={"fas fa-video fa-rotate-45"}/>
                <NavigationItem target={`${Routes.METER}`} icon={"fas fa-tachometer-alt"}/>
                <NavigationItem target={`${Routes.BOOKMARK}`} icon={"fas fa-bookmark"}/>
                <NavigationItem target={`${Routes.GW2}`} icon={"fas fa-dragon"}/>
                <NavigationItem target={`${Routes.SHOPPING}`} icon={"fas fa-shopping-cart"}/>
                <NavigationItem target={`${Routes.SNIPPET}`} icon={"fab fa-codepen"}/>
                <NavigationItem target={`${Routes.LOGOUT}`} icon={"fas fa-power-off"}/>
            </div>
        </nav>

    );
};

export default Navigation;

