class UpsertShoppingItemDto {
    id: number;
    item: string;

    constructor(id: number, item: string) {
        this.id = id;
        this.item = item;
    }
}

export default UpsertShoppingItemDto;