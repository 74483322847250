import React from "react";
import {inject, observer} from "mobx-react";
import style from '../syntxHighlightStyle/theme'
import SyntaxHighlighter from 'react-syntax-highlighter';
import ModalContainer from "../../modal/ModalContainer";

const SnippetItem = inject("snippetUiService")(observer(({snippetUiService, snippet}) => {

    const trim = (string, length) => {
        if(string.length >= length){
            return string.substring(0, length) + "...";
        }
        return string;
    };

    return (
        <div className="col-12 col-sm-4 wrapper">
            <div className="snippet border">
                <div className="row">
                    <div className="col-9 name">{trim(snippet.getDescription(), 22)}</div>
                    <div className="col-3 language text-right"><span className="languageText">{snippet.getLanguage()}</span></div>
                </div>
                <div className="row">
                    <div className="col-10">
                        {snippet.getTags().map((tag) => {
                            return <span key={tag.id} className="tag">{tag.name}</span>
                        })}
                    </div>
                    <div className="col-2 text-right">
                        <ModalContainer icon="eye"
                                        title={snippet.getDescription()}
                                        content={
                                            <SyntaxHighlighter language={snippet.getLanguage()} style={style}>
                                                {snippet.getSnippet()}
                                            </SyntaxHighlighter>
                                        }/>
                    </div>
                </div>
            </div>

        </div>
    );

}));

export default SnippetItem;