class UpsertBookmarkDto {
    id: number;
    link: string;
    description: string;

    constructor(id: number, link: string, description: string) {
        this.id = id;
        this.link = link;
        this.description = description;
    }
}

export default UpsertBookmarkDto;