import React from "react";
import {Row, Col} from "react-bootstrap";
import Gw2EventContainer from "./Gw2EventContainer";
import Clock from "./clock/Clock";
import PactMerchantLinks from "./PactMerchantLinks";
import Gw2PipCalculator from "./Gw2PipCalculator";

const Gw2Index = () => {
    return (
        <div>
            <Row>
                <Col><Clock /></Col>
            </Row>
            <hr className="d-none d-sm-block" />
            <Row className="d-none d-sm-block">
                <Col><PactMerchantLinks /></Col>
            </Row>
            <hr className="d-none d-sm-block" />
            <Row className="d-none d-sm-block">
                <Col><Gw2PipCalculator /></Col>
            </Row>
            <hr className="d-none d-sm-block" />
            <Row>
                <Col><Gw2EventContainer /></Col>
            </Row>
            <Row>
                <Col></Col>
            </Row>
        </div>
    );
};

export default Gw2Index;

