import React from "react";
import {inject, observer} from "mobx-react";
import {Form, Row, Col, Button} from "react-bootstrap";


const SnippetForm = inject("snippetUiService")(observer(({snippetUiService, handleClose, snippet}) => {

    const options = [
        { value: 'css', label: 'CSS' },
        { value: 'java', label: 'Java' },
        { value: 'javascript', label: 'JavaScript' },
        { value: 'bash', label: 'Bash' },
        { value: 'shell', label: 'Shell' },
        { value: 'json', label: 'Json' },
        { value: 'php', label: 'PHP' },
        { value: 'python', label: 'Python' },
        { value: 'sql', label: 'SQL' },
        { value: 'xml', label: 'Xml' },
        { value: 'yaml', label: 'Yaml' },
    ];

    return (
        <Form>
            <Row>
                <Col>
                    <Form.Group controlId="formDescription">
                        <Form.Control type="text"
                                      placeholder="Beschreibung"
                                      onChange={(e: any) => snippet.handleDescriptionChanged(e.target.value)}
                                      value={snippet.getDescription() !== null ? snippet.getDescription() : ""}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formSnippet">
                        <Form.Control as="textarea"
                                      rows={5}
                                      onChange={(e: any) => snippet.handleSnippetChanged(e.target.value)}
                                      value={snippet.getSnippet() !== null ? snippet.getSnippet() : ""}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Group controlId="formTags">
                        <Form.Control type="text"
                                      placeholder="tag tag tag"
                                      onChange={(e: any) => snippet.handleTagsChanged(e.target.value)}
                                      value={snippet.getTags() !== null ? snippet.getTags() : ""}
                        />
                    </Form.Group>
                </Col>
                <Col>
                    <Form.Group controlId="formLanguage">
                        <Form.Control as="select"
                                      onChange={(e: any) => snippet.handleLanguageChanged(e.target.value)}
                                      value={snippet.getLanguage() !== null ? snippet.getLanguage() : ""}
                        >
                            <option disabled value="">Sprache wählen</option>
                            {options.map((option) => {
                                return (<option key={option.value} value={option.value}>{option.label}</option>);
                            })}
                        </Form.Control>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button variant="primary"
                            block
                            onClick={() => snippetUiService.saveForm(snippet)}
                            disabled={snippetUiService.validateNotEmpty(snippet)}
                    >
                        Speichern
                    </Button>
                </Col>
                <Col>
                    <Button variant="warning"
                            block
                            onClick={handleClose}
                    >
                        Abbrechen
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}));

export default SnippetForm;