import axios, {AxiosPromise} from "axios";
import BookmarkDto from "./BookmarkDto";
import UpsertBookmarkCommand from "./data/UpsertBookmarkCommand";

class BookmarkClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    getAll(): AxiosPromise {
        const url = `${this._baseUrl}/intern/bookmarks`;
        return axios.get<Array<BookmarkDto>>(url);
    }

    saveNewBookmark(command: UpsertBookmarkCommand): AxiosPromise {
        const url = `${this._baseUrl}/intern/bookmark`;
        return axios.post<any>(url, command);
    }

    deactivateBookmark(command: UpsertBookmarkCommand): AxiosPromise {
        const url = `${this._baseUrl}/intern/bookmark/deactivate`;
        return axios.post<any>(url, command);
    }
}

export default BookmarkClient;