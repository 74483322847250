import {action, observable} from "mobx";
import VersionInfoClient from "../../api/versioninfo/VersionInfoClient";

class VersionInfoStore {
    @observable
    private backendVersion: string;

    @observable
    private frontendVersion: string;

    private client: VersionInfoClient;

    constructor(client: VersionInfoClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.backendVersion = "0.0.0";
        this.frontendVersion = "0.0.0";

    }

    getBackendVersion(): string {
        return this.backendVersion;
    }

    getFrontendVersion(): string {
        return this.frontendVersion;
    }

    @action
    private setBackendVersion(version: string): void {
        this.backendVersion = version;
    }

    @action
    private setFrontendVersion(version: string): void {
        this.frontendVersion = version;
    }

    fetchVersions(): void {
        this.client.getBackendVersion().then(response => {
            this.setBackendVersion(response.data);
        });
        this.client.getFrontendVersion().then(response => {
            this.setFrontendVersion(response.data)
        });
    }

}

export default VersionInfoStore;