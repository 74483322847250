import React, {useEffect} from "react";
import {Col, Row} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import pactIcon from "../../assets/pact_supply_merchant.png";

const PactMerchantLinks = inject("guildwarsUiService")(observer(({guildwarsUiService}) => {

    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                guildwarsUiService.loadTodaysPactMerchants();
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });

        },
        [
            guildwarsUiService
        ]);

    return (
        <div>
            <Row>
                <Col onClick={() => guildwarsUiService.copyToClipboard(guildwarsUiService.getTodaysPactMerchantLinks().getMerchants())}>
                    <img src={pactIcon} className="pactIcon" alt="pactIcon"/><small>{guildwarsUiService.getTodaysPactMerchantLinks().getMerchants()}</small>
                </Col>
            </Row>
        </div>
    );
}));

export default PactMerchantLinks;

