import React from "react";
import {inject, observer} from "mobx-react";


const BookmarkItem = inject("bookmarkUiService")(observer(({bookmarkUiService, bookmark}) => {

    return (
        <div className="row">
            <div className="col-11 simpleToolTip">
                <span className="simpleToolTipText">{bookmark.getDescription()}<br/>{bookmark.getCreated()}</span>
                <small><i className="fas fa-link"/></small>
                &nbsp;<a href={bookmark.getLink()} target="_blank" rel="noopener noreferrer">{bookmark.getLink()}</a>
            </div>
            <div className="col-1 text-right">
                <i className="fas fa-ban" onClick={() => bookmarkUiService.deactivateBookmark(bookmark.getId())}/>
            </div>
        </div>
    );

}));

export default BookmarkItem;