import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import Plug from "../../model/smartHome/Plug";
import PlugClient from "../../api/smartHome/PlugClient";
import PlugDto from "../../api/smartHome/PlugDto";

class PlugStore {
    @observable
    private all: Array<Plug>;

    private client: PlugClient;

    constructor(client: PlugClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<Plug>();
    }

    fetchAll(): AxiosPromise {
        return this.client.getAll().then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<PlugDto>): void {
        const result = new Array<Plug>();

        value.map(dto => {
            result.push(Plug.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<Plug> {
        return this.all;
    }

    togglePlug(id: number, status: string): AxiosPromise {
        return this.client.togglePlug(id, status).then(response => {
            this.setAll(response.data);
            return response;
        });
    }
}

export default PlugStore;