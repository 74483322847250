import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import BookmarkItem from "./BookmarkItem";


const BookmarkList = inject("bookmarkUiService")(observer(({bookmarkUiService}) => {
    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                bookmarkUiService.loadAll()
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });
        },
        [
            bookmarkUiService
        ]);

    return (
        <div className="bookmarkList">
            {bookmarkUiService.getAll().map(bookmark => {
                return (
                    <BookmarkItem key={bookmark.getId()} bookmark={bookmark}/>
                )
            })}
        </div>
    );

}));

export default BookmarkList;