import {action, computed, observable} from "mobx";
import Notification from "../../model/notification/Notification";

class NotificationStore {
    @observable
    private _notifications: Array<Notification> = [];

    @computed
    get notifications(): Array<Notification> {
        return this._notifications;
    }

    @action
    showNotification(notification: Notification): void {
        this._notifications.push(notification);
    }

    @action
    removeNotification(notification: Notification): void {
        const idx = this._notifications.indexOf(notification);
        if (idx >= 0) {
            this._notifications.splice(idx, 1);
        }
    }
}

export default NotificationStore;