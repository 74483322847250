import React from "react";
import {Container} from "react-bootstrap";
import SnippetList from "./list/SnippetList";
import SnippetHeader from "./header/SnippetHeader";


const SnippetIndex = () => {

    return (
        <Container fluid={true} className="snippet">
            <SnippetHeader/>
            <hr />
            <SnippetList/>
        </Container>
    );
};

export default SnippetIndex;