import NotificationUiService from "../notification/NotificationUiService";
import SnippetStore from "../../store/snippet/SnippetStore";
import Snippet from "../../model/snippet/Snippet";
import UpsertSnippet from "../../model/snippet/UpsertSnippet";
import UpsertSnippetCommand from "../../api/snippet/data/UpsertSnippetCommand";
import UpsertSnippetDto from "../../api/snippet/UpsertSnippetDto";
import _ from "lodash";

class SnippetUiService {
    private snippetStore: SnippetStore;

    constructor(snippetStore: SnippetStore) {
        this.snippetStore = snippetStore;
    }

    loadAll(): void {
        this.snippetStore.fetchAll().catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Snippets konnten nicht geladen werden!");
        });
    }

    getAll(): Array<Snippet> {
        return this.snippetStore.getAll();
    }

    getFiltered(value: string): Array<Snippet> {
        if(value === ""){
            return this.snippetStore.getAll();
        }

        return _.filter(this.snippetStore.getAll(), function (o) {
            return o.getDescription().toLowerCase().includes(value.toLowerCase()) || o.getSnippet().toLowerCase().includes(value.toLowerCase());
        });
    }

    saveForm(snippet: UpsertSnippet): void {
        const upsertSnippetDto = new UpsertSnippetDto(null, snippet.getDescription(), snippet.getSnippet(), snippet.getLanguage(), snippet.getTags());
        const command = new UpsertSnippetCommand(upsertSnippetDto);
        this.snippetStore.saveNewSnippet(command).then(response => {
            snippet.clear();
            this.loadAll();
        }).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Snippets konnte nicht gespeichert werden!");
        });
    }

    validateNotEmpty(snippet: UpsertSnippet): boolean {
        return snippet.getDescription() === "" || snippet.getSnippet() === "";
    }

    handleSnippetFilterChanged(value: string): void {
        this.snippetStore.filterSnippets(value)
    }


    getFilterValue(): string{
        return this.snippetStore.getFilterValue();
    }

}

export default SnippetUiService;