import UpsertBookmarkDto from "../UpsertBookmarkDto";

class UpsertBookmarkCommand {
    private readonly bookmark: UpsertBookmarkDto;

    constructor(bookmark: UpsertBookmarkDto) {
        this.bookmark = bookmark;
    }

    getNewBookmark(): UpsertBookmarkDto {
        return this.bookmark;
    }
}

export default UpsertBookmarkCommand;