import React from "react";
import {inject, observer} from "mobx-react";
import GatherMeterReadings from "../gather/GatherMeterReadings";
import TotalOverview from "./TotalOverview";
import ConsumptionOverview from "./ConsumptionOverview";
import {Button, Col, Row} from "react-bootstrap";
import moment from "moment";


const Statistic = inject("meterUiService")(observer(({meterUiService}) => {

    const selectors = [
        {name: "Übersicht", target: "totalOverview", component: <TotalOverview/>},
        {name: "Verbrauch", target: "consumptionOverview", component: <ConsumptionOverview/>}
    ];

    const periods = [
        {name: "alle", startDate: "2019-01-01", endDate: moment().format("YYYY-MM-DD")},
        {name: "2019", startDate: "2019-01-01", endDate: "2020-01-01"},
        {name: "2020", startDate: "2020-01-01", endDate: "2021-01-01"},
        {name: "2021", startDate: "2021-01-01", endDate: "2022-01-01"},
        {name: "2022", startDate: "2022-01-01", endDate: "2023-01-01"}
    ];


    return (
        <>
            <Row className="meterSelect hideDesktop">
                {selectors.map((selector) => {
                    return (
                        <Col key={selector.target}>
                            <Button
                                className={meterUiService.getActiveStatisticView() === selector.target ? "btn-block shadow-none active" : "btn-block shadow-none"}
                                variant="secondary"
                                onClick={() => meterUiService.changeActiveStatisticView(selector.target)}>
                                {selector.name}
                            </Button>
                        </Col>
                    );
                })}
            </Row>
            <Row className="period">
                {periods.map((period) => {
                    return (
                            <Button
                                key={period.name}
                                className="btn-flex shadow-none"
                                variant="secondary"
                                onClick={() => meterUiService.loadOverviewForPeriod(period.startDate, period.endDate)}>
                                {period.name}
                            </Button>
                    );
                })}
            </Row>
            <Row className="meterContent">
                {selectors.map((selector) => {
                    return (
                        meterUiService.getActiveStatisticView() === selector.target
                            ?
                            (<Col key={selector.target}>{selector.component}</Col>)
                            :
                            null
                    );
                })}
            </Row>
        </>
    );


}));

export default Statistic;