import {action, observable} from "mobx";

class UpsertBookmark {

    @observable
    private id: number;

    @observable
    private link: string;

    @observable
    private description: string;

    @observable
    private active: boolean;

    constructor() {
        this.init();
    }

    @action
    private init(): void {
        this.id = null;
        this.link = "";
        this.description = "";
        this.active = null;
    }

    getId(): number {
        return this.id;
    }

    getLink(): string {
        return this.link;
    }

    getDescription(): string {
        return this.description;
    }

    isActive(): boolean {
        return this.active;
    }

    @action
    setId(id: number): void {
        this.id = id;
    }

    @action
    setLink(link: string): void {
        this.link = link;
    }

    @action
    setDescription(description: string): void {
        this.description = description;
    }

    @action
    setActive(active: boolean): void {
        this.active = active;
    }

    handleLinkChanged(value: string): void {
        this.setLink(value);
    }

    handleDescriptionChanged(value: string): void {
        this.setDescription(value)
    }

    handleActiveChanged(value: boolean): void {
        this.setActive(value);
    }

    clear(): void {
        this.init();
    }


    // static createFrom(dto: UpsertShoppingItemDto): ShoppingItem {
    //     return new ShoppingItem(dto.id, dto.created, dto.updated, dto.link, dto.description, dto.active)
    // }
}

export default UpsertBookmark;