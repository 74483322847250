import MeterConsumptionDto from "../../api/meter/MeterConsumptionDto";

class MeterConsumption {
    private _differenceReading: number;
    private _differenceDays: number;
    private _averagePerDay: number;

    constructor(differenceReading: number, differenceDays: number, averagePerDay: number) {
        this._differenceReading = differenceReading;
        this._differenceDays = differenceDays;
        this._averagePerDay = averagePerDay;
    }


    get differenceReading(): number {
        return this._differenceReading;
    }

    get differenceDays(): number {
        return this._differenceDays;
    }

    get averagePerDay(): number {
        return this._averagePerDay;
    }

    static createFrom(dto: MeterConsumptionDto): MeterConsumption {
        return new MeterConsumption(dto.differenceReading ||0 , dto.differenceDays, dto.averagePerDay)
    }
}

export default MeterConsumption;