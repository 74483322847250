import React, {useEffect} from "react";
import GatherMeterReadings from "./gather/GatherMeterReadings";
import {inject, observer} from "mobx-react";
import Statistic from "./statistic/Statistic";
import {Button, Col, Row} from "react-bootstrap";

const MeterIndex = inject("meterUiService")(observer(({meterUiService}) => {
    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                meterUiService.loadAll();
                meterUiService.loadOverview();
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });
        },
        [
            meterUiService
        ]);

    const selectors = [
        {name: "Statistic", target: "statistic", component: <Statistic/>},
        {name: "Erfassen", target: "gather", component: <GatherMeterReadings/>}
    ];


    return (
        <>
            <Row className="meterSelect hideDesktop">
                {selectors.map((selector) => {
                    return (
                        <Col key={selector.target}>
                            <Button
                                className={meterUiService.getActiveView() === selector.target ? "btn-block shadow-none active" : "btn-block shadow-none"}
                                variant="secondary"
                                onClick={() => meterUiService.changeActiveView(selector.target)}>
                                {selector.name}
                            </Button>
                        </Col>
                    );
                })}
            </Row>
            <Row className="meterContent">
                {selectors.map((selector) => {
                    return (
                        meterUiService.getActiveView() === selector.target
                            ?
                            (<Col key={selector.target}>{selector.component}</Col>)
                            :
                            null
                    );
                })}
            </Row>
        </>
    );
}));

export default MeterIndex;
