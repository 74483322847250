import React from 'react';
import moment from 'moment';

const Waste = (props) => {

    const residual = [
        '29.12.2021',
        '12.01.2022',
        '25.01.2022',
        '08.02.2022',
        '22.02.2022',
        '08.03.2022',
        '22.03.2022',
        '05.04.2022',
        '21.04.2022',
        '03.05.2022',
        '17.05.2022',
        '31.05.2022',
        '14.06.2022',
        '28.06.2022',
        '12.07.2022',
        '26.07.2022',
        '09.08.2022',
        '23.08.2022',
        '06.09.2022',
        '20.09.2022',
        '05.10.2022',
        '18.10.2022',
        '02.11.2022',
        '15.11.2022',
        '29.11.2022',
        '13.12.2022',
        '28.12.2022'
    ];

    const paper = [
        '04.01.2022',
        '31.01.2022',
        '28.02.2022',
        '28.03.2022',
        '26.04.2022',
        '23.05.2022',
        '20.06.2022',
        '18.07.2022',
        '15.08.2022',
        '12.09.2022',
        '10.10.2022',
        '07.11.2022',
        '05.12.2022'
    ];

    const glass = [
        '11.01.2022',
        '07.02.2022',
        '07.03.2022',
        '04.04.2022',
        '02.05.2022',
        '30.05.2022',
        '27.06.2022',
        '25.07.2022',
        '22.08.2022',
        '19.09.2022',
        '17.10.2022',
        '14.11.2022',
        '12.12.2022'
    ];

    const plastic = [
        '05.01.2022',
        '01.02.2022',
        '01.03.2022',
        '29.03.2022',
        '27.04.2022',
        '24.05.2022',
        '21.06.2022',
        '19.07.2022',
        '16.08.2022',
        '13.09.2022',
        '11.10.2022',
        '08.11.2022',
        '06.12.2022'
    ];

    const getNextDates = (dates) => {
        let nextDays = [];
        for (let i = 0; i <= dates.length; i++) {
            let diff = moment(dates[i], "DD.MM.YYYY").diff(moment());
            if (moment.duration(diff).asDays() > -1) {
                nextDays.push(dates[i])
            }
            if (nextDays.length === 3) {
                return nextDays;
            }
        }
        return nextDays;
    };

    const className = (date) => {
        let diff = moment(date, "DD.MM.YYYY").diff(moment());
        if (moment.duration(diff).asDays() < 1) {
            return 'wasteDate wasteDateAlert';
        }
        if (moment.duration(diff).asDays() < 2) {
            return 'wasteDate wasteDateWarning';
        }
        return 'wasteDate';
    };

    return (
        <div className="row">
            <div className="col-6 col-md-3 waste">
                <div className="residual">
                        <span className="fa-stack fa-1x">
                            <i className="fas fa-trash-alt"></i>
                        </span>
                </div>
                {getNextDates(residual).map((date, key) => {
                    return <div key={key}
                                className={className(date)}>{moment(date, 'DD.MM.YYYY').locale("de").format('dd DD.MM.YYYY')}</div>
                })}
            </div>

            <div className="col-6 col-md-3 waste">
                <div className="paper">
                        <span className="fa-stack fa-1x">
                            <i className="fas fa-trash fa-stack-1x sc-outer"></i>
                            <i className="fas fa-sticky-note fa-stack-1x fa-inverse sc-inner"></i>
                        </span>
                </div>
                {getNextDates(paper).map((date, key) => {
                    return <div key={key}
                                className={className(date)}>{moment(date, 'DD.MM.YYYY').locale("de").format('dd DD.MM.YYYY')}</div>
                })}
            </div>

            <div className="col-6 col-md-3 waste">
                <div className="plastic">
                        <span className="fa-stack fa-1x">
                            <i className="fas fa-trash fa-stack-1x sc-outer"></i>
                            <i className="fas fa-recycle fa-stack-1x fa-inverse sc-inner"></i>
                        </span>
                </div>
                {getNextDates(plastic).map((date, key) => {
                    return <div key={key}
                                className={className(date)}>{moment(date, 'DD.MM.YYYY').locale("de").format('dd DD.MM.YYYY')}</div>
                })}
            </div>

            <div className="col-6 col-md-3 waste">
                <div className="glass">
                        <span className="fa-stack fa-1x">
                            <i className="fas fa-trash fa-stack-1x sc-outer"></i>
                            <i className="fas fa-circle fa-stack-1x fa-inverse sc-inner"></i>
                        </span>
                </div>
                {getNextDates(glass).map((date, key) => {
                    return <div key={key}
                                className={className(date)}>{moment(date, 'DD.MM.YYYY').locale("de").format('dd DD.MM.YYYY')}</div>
                })}
            </div>
        </div>
    )
}

export default Waste;



