import UpsertMeterReadingDto from "../UpsertMeterReadingDto";

class UpsertMeterReadingsCommand {
    private readonly meterReadings: Array<UpsertMeterReadingDto>;


    constructor(meterReadings: Array<UpsertMeterReadingDto>) {
        this.meterReadings = meterReadings;
    }
}

export default UpsertMeterReadingsCommand;