import UserSessionStore from "../../store/security/UserSessionStore";

class SecurityUiService {
    private userSessionStore: UserSessionStore;

    constructor(userSessionStore: UserSessionStore) {
        this.userSessionStore = userSessionStore;
    }

    isLoggedIn(): boolean {
        return this.userSessionStore.isAuthenticated();
    }

    getUsername(): string {
        return this.userSessionStore.getUsername();
    }

    logout(): void {
        this.userSessionStore.clear();
    }

    hasAdminRole(): boolean {
        return this.userSessionStore.hasAdminRole();
    }
}

export default SecurityUiService;