import NotificationUiService from "../notification/NotificationUiService";
import ShoppingItemStore from "../../store/shopping/ShoppingItemStore";
import ShoppingItem from "../../model/shopping/ShoppingItem";
import UpsertShoppingItem from "../../model/shopping/UpsertShoppingItem";
import UpsertShoppingItemDto from "../../api/shopping/UpsertShoppingItemDto";
import UpsertShoppingItemCommand from "../../api/shopping/data/UpsertShoppingItemCommand";

class ShoppingItemUiService {
    private shoppingItemStore: ShoppingItemStore;

    constructor(shoppingItemStore: ShoppingItemStore) {
        this.shoppingItemStore = shoppingItemStore;
    }

    loadAll(): void {
        this.shoppingItemStore.fetchAll(this.getListView()).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Items konnten nicht geladen werden!");
        });
    }

    getAll(): Array<ShoppingItem> {
        return this.shoppingItemStore.getAll();
    }

    getListView(): boolean {
        return this.shoppingItemStore.getListView();
    }

    changeListView(): void {
        this.shoppingItemStore.changeListView();
    }

    saveForm(shoppingItem: UpsertShoppingItem): void {
        const upsertShoppingItemDto = new UpsertShoppingItemDto(null, shoppingItem.getItem());
        const command = new UpsertShoppingItemCommand(upsertShoppingItemDto);
        this.shoppingItemStore.saveNewShoppingItem(command).then(response => {
            shoppingItem.clear();
            this.loadAll();
        }).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Item konnte nicht gespeichert werden!");
        });
    }

    changeShoppingItemStatus(id: number): void {
        const upsertShoppingItemDto = new UpsertShoppingItemDto(id, null);
        const command = new UpsertShoppingItemCommand(upsertShoppingItemDto);
        this.shoppingItemStore.deactivateShoppingItem(command).then(response => {
            this.loadAll();
        }).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Item konnte nicht gespeichert werden!");
        });
    }


}

export default ShoppingItemUiService;