import React, {useState} from "react";
import {observer} from "mobx-react";

interface RoomProps {
    ccState: any;
    saveTemperature: any;
}

const Room = (observer(({ccState, saveTemperature}: RoomProps) => {

    const [temperature, setTemperature] = useState(ccState.currentSet);

    const setNewTemperature = value => {
        setTemperature(value)
    }

    const saveNewTemperature = value => {
        saveTemperature(ccState.room, value)
    }

    return (
        <>
            <div className="row mt-5">
                <div className={ccState.room + " col-3"}></div>
                <div className="col-9 text-right">{temperature} °C</div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    <input type="range"
                           className="custom-range"
                           min="5"
                           max="30"
                           step="0.5"
                           onChange={(e: any) => setNewTemperature(e.target.value)}
                           onTouchEnd={(e: any) => saveNewTemperature(e.target.value)}
                           value={temperature} id="customRange3"/>
                </div>
            </div>
        </>
    );
}));

export default Room;