import React, {useEffect} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import Overview from "./view/Overview";
import Detail from "./view/Detail";

const SmartHomeIndex = inject("smartHomeUiService")(observer(({smartHomeUiService}) => {
    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                smartHomeUiService.loadAll();
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });
            const interval = setInterval(() => fetchData(), 30000);
            return () => clearInterval(interval);
        },
        [
            smartHomeUiService
        ]);

    const selectors = [
        {name: "Übersicht", target: "overview", component: <Overview/>},
        {name: "Detail", target: "detail", component: <Detail/>}
    ];


    return (
        <div>
            <Row className="smartHomeSelect hideDesktop">
                {selectors.map((selector) => {
                    return (
                        <Col key={selector.target}>
                            <Button
                                className={smartHomeUiService.getActiveView() === selector.target ? "btn-block active" : "btn-block"}
                                variant="secondary"
                                onClick={() => smartHomeUiService.changeActiveView(selector.target)}>
                                {selector.name}
                            </Button>
                        </Col>
                    );
                })}
            </Row>
            <Row className="smartHomeContent">
                {selectors.map((selector) => {
                    return (
                        smartHomeUiService.getActiveView() === selector.target
                            ?
                            (<Col key={selector.target}>{selector.component}</Col>)
                            :
                            null
                    );
                })}
            </Row>
        </div>
    );
}));

export default SmartHomeIndex;