import uuidv4 from "uuid/v4";
import Notification from "./Notification";

abstract class DefaultNotification implements Notification {
    id: string;
    key: string;
    message: string;
    headline: string;
    timeout: number;
    abstract type: string;

    constructor(headline: string, message: string) {
        this.id = uuidv4();
        this.key = uuidv4();
        this.timeout = 3000;
        this.headline = headline;
        this.message = message;
    }
}

export default DefaultNotification;