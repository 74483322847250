import axios, {AxiosPromise} from "axios";
import SnippetDto from "./SnippetDto";
import UpsertSnippetCommand from "./data/UpsertSnippetCommand";


class SnippetClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    getAll(): AxiosPromise {
        const url = `${this._baseUrl}/intern/snippets/`;
        return axios.get<Array<SnippetDto>>(url);
    }

    saveNewSnippet(command: UpsertSnippetCommand): AxiosPromise {
        const url = `${this._baseUrl}/intern/snippet/`;
        return axios.post<any>(url, command);
    }
}

export default SnippetClient;