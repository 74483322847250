import NotificationUiService from "../notification/NotificationUiService";
import MeterStore from "../../store/meter/MeterStore";
import MeterReading from "../../model/meter/MeterReading";
import UpsertShoppingItem from "../../model/shopping/UpsertShoppingItem";
import UpsertShoppingItemDto from "../../api/shopping/UpsertShoppingItemDto";
import UpsertShoppingItemCommand from "../../api/shopping/data/UpsertShoppingItemCommand";
import moment from "moment";
import UpsertMeterReadingDto from "../../api/meter/UpsertMeterReadingDto";
import UpsertMeterReadingsCommand from "../../api/meter/data/UpsertMeterReadingsCommand";
import MeterTotalOverview from "../../model/meter/MeterTotalOverview";

class MeterUiService {
    private meterStore: MeterStore;

    private date = moment(new Date()).format("YYYY-MM-DD")
    private startDate = "2019-09-01"
    private endDate = moment(new Date()).format("YYYY-MM-DD")

    constructor(meterStore: MeterStore) {
        this.meterStore = meterStore;
    }

    loadAll(): void {
        this.meterStore.fetchAll(this.date).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Zähler konnten nicht geladen werden!");
        });
    }

    loadOverview(): void {
        this.meterStore.fetchOverview(this.startDate, this.endDate).catch((e) => {
            console.log(e)
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Zählerübersicht konnte nicht geladen werden!");
        });
    }

    loadOverviewForPeriod(startDate: string, endDate: string): void {
        this.startDate = startDate;
        this.endDate = endDate;
        this.loadOverview();
    }

    getAll(): Array<MeterReading> {
        return this.meterStore.getAll();
    }

    getOverview(): MeterTotalOverview {
        return this.meterStore.getOverview();
    }

    changeActiveView(view: string): void {
        this.meterStore.setActiveView(view)
    }

    getActiveView(): string {
        return this.meterStore.getActiveView();
    }

    changeActiveStatisticView(view: string): void {
        this.meterStore.setActiveStatisticView(view)
    }

    getActiveStatisticView(): string {
        return this.meterStore.getActiveStatisticView();
    }

    changeDate(value): void {
        this.date = value;
        this.loadAll();
    }

    getSelectedDate(): string {
        return this.date;
    }

    update(meterId, value): void {
        console.log(value)
        let readings = this.meterStore.getAll();
        let objIndex = readings.findIndex((obj => obj.meterId == meterId));
        readings[objIndex].meterReading = value
        readings[objIndex].readingDate = this.date

        this.meterStore.update(readings)
    }

    updateCorrection(meterId, value): void {
        console.log(value)
        let readings = this.meterStore.getAll();
        let objIndex = readings.findIndex((obj => obj.meterId == meterId));
        readings[objIndex].meterReadingCorrection = value

        this.meterStore.update(readings)
    }

    saveReadings(event){
        event.preventDefault();
        const readings = this.meterStore.getAll();


        const result = new Array<UpsertMeterReadingDto>();

        readings.map(reading => {
            result.push(UpsertMeterReadingDto.createFrom(reading));
            return reading;
        });
        const command = new UpsertMeterReadingsCommand(result)
        this.meterStore.saveReadings(command).then(response => {
            NotificationUiService.getInstance().showInfoNotification("Gespeichert", "Die Zählerstände wurden gespeichert!");
        }).catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Zählerstände konnte nicht gespeichert werden!");
        });
    }
}

export default MeterUiService;