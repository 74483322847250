import React from "react";
import {observer} from "mobx-react";
import ModalContainer from "../../../modal/ModalContainer";


const Livestream = (observer(({deviceName, icon}) => {

    return (
        <div className={deviceName + " liveStream btn"}>
            <ModalContainer className="modal-full"
                            icon={icon}
                            name="stream"
                            content={
                                <img src="http://beshir.softcreations.de:5073?action=stream" width="100%" alt="stream"/>
                            }>
            </ModalContainer>
        </div>
    );

}));

export default Livestream;