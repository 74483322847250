import React from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import {inject, observer} from "mobx-react";


const ShoppingItemForm = inject("shoppingItemUiService")(observer(({shoppingItemUiService, shoppingItem}) => {

    return (
        <Form className="shoppingItemForm">
            <Form.Row>
                <Col>
                    <InputGroup>
                        <Form.Control
                            placeholder="http://www.example.com"
                            onChange={(e: any) => shoppingItem.handleItemChanged(e.target.value)}
                            value={shoppingItem.getItem() !== null ? shoppingItem.getItem() : ""}
                        />
                        <InputGroup.Append>
                            <Button type="submit" variant="primary" onClick={() => shoppingItemUiService.saveForm(shoppingItem)}>
                                <i className="fas fa-save"/>
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Col>
            </Form.Row>
        </Form>
    );

}));

export default ShoppingItemForm;