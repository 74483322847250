import {action, observable} from "mobx";
import PipDivision from "../../model/guildwars/PipDivision";
import PipStats from "../../model/guildwars/PipStats";

class PipStore {
    @observable
    private selectedDivision: PipDivision;

    @observable
    private selectedDivisionRank: number;

    @observable
    private selectedDivisionRankPoints: number;

    @observable
    private pipsPerTick: number;

    @observable
    private calculatedStats: PipStats;

    @observable
    private showPipCalculation: boolean;

    @action
    private init(): void {
        this.selectedDivision = null;
        this.selectedDivisionRank = null;
        this.selectedDivisionRankPoints = null;
        this.pipsPerTick = null;
        this.calculatedStats = null;
        this.showPipCalculation = null;
    }

    changePipCalculationShowStatus(): void {
        const currentState = this.showPipCalculation;
        console.log(currentState)
        this.setShowPipCalculation(currentState ? false : true);
    }

    selectDivision(selectedDivision: PipDivision): void {
        this.setSelectedDivision(selectedDivision);
    }

    selectDivisionRank(rank: number): void {
        this.setSelectedDivisionRank(rank);
    }

    selectDivisionRankPoints(points: number): void {
        this.setSelectedDivisionRankPoints(points);
    }

    selectPipsPerTick(pips: number): void {
        this.setPipsPerTick(pips);
    }

    updateStats(calculatedStats: PipStats): void {
        this.setStats(calculatedStats);
    }

    @action
    private setShowPipCalculation(status: boolean): void {
        this.showPipCalculation = status;
    }

    @action
    private setSelectedDivision(selectedDivision: PipDivision): void {
        this.selectedDivision = selectedDivision;
    }

    @action
    private setSelectedDivisionRank(rank: number): void {
        this.selectedDivisionRank = rank;
    }

    @action
    private setSelectedDivisionRankPoints(points: number): void {
        this.selectedDivisionRankPoints = points;
    }

    @action
    private setPipsPerTick(pips: number): void {
        this.pipsPerTick = pips;
    }

    @action
    private setStats(calculatedStats: PipStats): void {
        this.calculatedStats = calculatedStats;
    }

    getShowPipCalculation(): boolean {
        console.log(this.showPipCalculation)
        return this.showPipCalculation;
    }

    getSelectedDivision(): PipDivision {
        return this.selectedDivision;
    }

    getSelectedDivisionRank(): number {
        return this.selectedDivisionRank;
    }

    getSelectedDivisionRankPoints(): number {
        return this.selectedDivisionRankPoints;
    }

    getPipsPerTick(): number {
        return this.pipsPerTick;
    }

    getStats(): PipStats {
        return this.calculatedStats;
    }

}

export default PipStore;