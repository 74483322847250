class UpsertSnippetDto {
    id: number;
    description: string;
    snippet: string;
    language: string;
    tags: string

    constructor(id: number, description: string, snippet: string, language: string, tags: string) {
        this.id = id;
        this.description = description;
        this.snippet = snippet;
        this.language = language;
        this.tags = tags;
    }
}

export default UpsertSnippetDto;