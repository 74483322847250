import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import {Provider} from "mobx-react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Login from "./components/security/Login";
import Logout from "./components/security/Logout";
import SecuredComponent from "./components/security/SecuredComponent";
import AuthClient from "./api/auth/AuthClient";
import UserSessionStore from "./store/security/UserSessionStore";
import SecurityUiService from "./services/security/SecurityUiService";
import LoginUiService from "./services/security/LoginUiService";
import LoginStore from "./store/security/LoginStore";
import NotificationComponent from "./components/notfication/NotificationComponent";
import NotificationUiService from "./services/notification/NotificationUiService";
import Config from "./Config";
import SessionTimoutConfiguration from "./api/auth/SessionTimoutConfiguration";
import Routes from "./api/Routes";

const notificationUiService = NotificationUiService.getInstance();
const authClient = new AuthClient(Config.AUTH_API);
const userSessionStore = new UserSessionStore(authClient);
const securityUiService = new SecurityUiService(userSessionStore);
const loginUiService = new LoginUiService(new LoginStore(authClient), userSessionStore);
userSessionStore.init();

const sessionTimoutConfiguration = new SessionTimoutConfiguration(userSessionStore);
sessionTimoutConfiguration.configureSessionTimeout();

const stores = {
    notificationUiService,
    securityUiService,
    loginUiService
};

ReactDOM.render(
    <Provider {...stores}>
        <React.Fragment>
            <NotificationComponent/>
            <BrowserRouter>
                <React.Fragment>
                    <Switch>
                        {/* Authentication Routes */}
                        <Route exact path={Routes.LOGIN} component={Login}/>
                        <Route exact path={Routes.LOGOUT} component={Logout}/>
                        {/* Authenticated Routes */}
                        <Route path={Routes.INDEX} component={SecuredComponent}/>
                        <Route path={Routes.SMARTHOME} component={SecuredComponent}/>
                        <Route path={Routes.BOOKMARK} component={SecuredComponent}/>
                        <Route path={Routes.SHOPPING} component={SecuredComponent}/>
                    </Switch>
                </React.Fragment>
            </BrowserRouter>
        </React.Fragment>
    </Provider>, document.getElementById("root")
);