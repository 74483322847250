import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import SnippetClient from "../../api/snippet/SnippetClient";
import SnippetDto from "../../api/snippet/SnippetDto";
import Snippet from "../../model/snippet/Snippet";
import UpsertSnippetCommand from "../../api/snippet/data/UpsertSnippetCommand";


class SnippetStore {
    @observable
    private all: Array<Snippet>;

    private client: SnippetClient;

    @observable
    private filterValue: string

    constructor(client: SnippetClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<Snippet>();
        this.filterValue = "";
    }

    fetchAll(): AxiosPromise {
        return this.client.getAll().then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<SnippetDto>): void {
        const result = new Array<Snippet>();

        value.map(dto => {
            result.push(Snippet.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<Snippet> {
        return this.all;
    }

    saveNewSnippet(command: UpsertSnippetCommand): Promise<any> {
        return this.client.saveNewSnippet(command);
    }

    filterSnippets(value: string): void {
        this.filterValue = value;
    }

    getFilterValue(): string {
        return this.filterValue;
    }

}

export default SnippetStore;