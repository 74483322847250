import PactMerchantDto from "../../api/guildwars/PactMerchantDto";

class PactMerchant {
    private merchants: string;
    private validSince: string;

    constructor(merchants: string, validSince: string) {
        this.merchants = merchants;
        this.validSince = validSince
    }

    getMerchants(): string {
        return this.merchants;
    }

    getValidSince(): string {
        return this.validSince;
    }

    static createFrom(dto: PactMerchantDto): PactMerchant {
        return new PactMerchant(dto.merchants, dto.validSince)
    }
}

export default PactMerchant;