import {action, observable} from "mobx";

class UpsertSnippet {

    @observable
    private id: number;

    @observable
    private description: string;

    @observable
    private snippet: string;

    @observable
    private tags: string;

    @observable
    private language: string;

    constructor() {
        this.init();
    }

    @action
    private init(): void {
        this.id = null;
        this.description = "";
        this.snippet = "";
        this.tags = "";
        this.language = "";
    }

    getId(): number {
        return this.id;
    }

    getDescription(): string {
        return this.description;
    }

    getSnippet(): string {
        return this.snippet;
    }

    getTags(): string {
        return this.tags;
    }

    getLanguage(): string {
        return this.language;
    }

    @action
    setId(id: number): void {
        this.id = id;
    }

    @action
    setDescription(description: string): void {
        this.description = description;
    }

    @action
    setSnippet(snippet: string): void {
        this.snippet = snippet;
    }

    @action
    setTags(tags: string): void {
        this.tags = tags;
    }

    @action
    setLanguage(language: string): void {
        this.language = language;
    }

    handleDescriptionChanged(value: string): void {
        this.setDescription(value)
    }

    handleSnippetChanged(value: string): void {
        this.setSnippet(value);
    }

    handleTagsChanged(value: string): void {
        this.setTags(value);
    }

    handleLanguageChanged(value: string): void {
        this.setLanguage(value);
    }

    clear(): void {
        this.init();
    }


    // static createFrom(dto: UpsertShoppingItemDto): ShoppingItem {
    //     return new ShoppingItem(dto.id, dto.created, dto.updated, dto.link, dto.description, dto.active)
    // }
}

export default UpsertSnippet;