import BookmarkDto from "../../api/bookmark/BookmarkDto";

class Bookmark {
    private id: number;
    private created: string;
    private updated: string;
    private link: string;
    private description: string;
    private active: boolean;

    constructor(id: number, created: string, updated: string, link: string, description: string, active: boolean) {
        this.id = id;
        this.created = created;
        this.updated = updated;
        this.link = link;
        this.description = description;
        this.active = active;
    }

    getId(): number {
        return this.id;
    }

    getCreated(): string {
        return this.created;
    }

    getUpdated(): string {
        return this.updated;
    }

    getLink(): string {
        return this.link;
    }

    getDescription(): string {
        return this.description;
    }

    isActive(): boolean {
        return this.active;
    }


    static createFrom(dto: BookmarkDto): Bookmark {
        return new Bookmark(dto.id, dto.created, dto.updated, dto.link, dto.description, dto.active)
    }
}

export default Bookmark;