enum Routes {
    INDEX = "/",
    SMARTHOME = "/smarthome",
    METER = "/meter",
    BOOKMARK = "/bookmark",
    SHOPPING = "/shopping",
    SNIPPET = "/snippet",
    GW2 = "/gw2",
    LOGIN = "/login",
    LOGOUT = "/logout"
}

export default Routes;