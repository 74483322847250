import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import Room from "../../model/smartHome/Room";
import RoomDto from "../../api/smartHome/RoomDto";
import RoomClient from "../../api/smartHome/RoomClient";

class RoomStore {
    @observable
    private all: Array<Room>;

    private client: RoomClient;

    constructor(client: RoomClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<Room>();
    }

    fetchAll(): AxiosPromise {
        return this.client.getAll().then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<RoomDto>): void {
        const result = new Array<Room>();

        value.map(dto => {
            result.push(Room.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<Room> {
        return this.all;
    }
}

export default RoomStore;