import PlugDto from "../../api/smartHome/PlugDto";

class Plug {
    private id: number;
    private on: boolean;
    private alert: string;
    private mode: number;
    private reachable: boolean;
    private name: string;
    private type: string;

    constructor(id: number, on: boolean, alert: string, mode: number, reachable: boolean, name: string, type: string) {
        this.id = id;
        this.on = on;
        this.alert = alert;
        this.mode = mode;
        this.reachable = reachable;
        this.name = name;
        this.type = type;
    }

    getId(): number {
        return this.id;
    }

    getOn(): boolean {
        return this.on;
    }

    getAlert(): string {
        return this.alert;
    }

    getMode(): number {
        return this.mode;
    }

    getReachable(): boolean {
        return this.reachable;
    }

    getName(): string {
        return this.name;
    }

    getType(): string {
        return this.type;
    }

    static createFrom(dto: PlugDto): Plug {
        return new Plug(dto.id, dto.on, dto.alert, dto.mode, dto.reachable, dto.name, dto.type)
    }
}

export default Plug;