import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";

const VersionInfo = inject("versionInfoUiService")(observer(({versionInfoUiService}) => {
    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                versionInfoUiService.fetchVersions();
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });

        },
        [
            versionInfoUiService
        ]);

    return (
        <div className="version-info">
            <div><i className="fa fa-cloud" aria-hidden="true"/> {versionInfoUiService.getBackendVersion()}</div>
            <div><i className="fa fa-desktop" aria-hidden="true"/> {versionInfoUiService.getFrontendVersion()}</div>
        </div>
    );
}));

export default VersionInfo;