import axios, {AxiosPromise} from "axios";
import ClimateControlDto from "./ClimateControlDto";

class ClimateControlClient {
    private readonly _baseUrl: string;
    private readonly _homeUrl: string;

    constructor(baseUrl: string, homeUrl: string) {
        this._baseUrl = baseUrl;
        this._homeUrl = homeUrl;
    }

    getAll(): AxiosPromise {
        const url = `${this._baseUrl}/intern/climate-control/get/states/`;
        // const url = `${this._baseUrl}/intern/home/status/climate-control`;
        return axios.get<Array<ClimateControlDto>>(url);
    }

    setTemperature(name: string, temperature: number): AxiosPromise {
        const url = `${this._baseUrl}/intern/climate-control/`;
        return axios.post(url, {"name":name, "temperature":temperature})
            .then(response => this.getAll());
    }
}

export default ClimateControlClient;