import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import GuildwarsClient from "../../api/guildwars/GuildwarsClient";
import PactMerchantDto from "../../api/guildwars/PactMerchantDto";
import PactMerchant from "../../model/guildwars/PactMerchants";


class PactMerchantStore {
    @observable
    private merchants: PactMerchant;

    private client: GuildwarsClient;

    constructor(client: GuildwarsClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.merchants = new PactMerchant('', '');
    }

    fetchAll(): AxiosPromise {
        return this.client.getTodaysPactMerchantLinks().then(response => {
            this.setMerchantLinks(response.data);
            return response;
        });
    }

    @action
    private setMerchantLinks(dto: PactMerchantDto): void {
        this.merchants = PactMerchant.createFrom(dto);
    }

    getMerchants(): PactMerchant {
        return this.merchants;
    }
}

export default PactMerchantStore;