import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import SensorDto from "../../api/smartHome/SensorDto";
import SensorClient from "../../api/smartHome/SensorClient";
import Sensor from "../../model/smartHome/Sensor";

class SensorStore {
    @observable
    private all: Array<Sensor>;

    private client: SensorClient;

    constructor(client: SensorClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<Sensor>();
    }

    fetchAll(): AxiosPromise {
        return this.client.getAll().then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<SensorDto>): void {
        const result = new Array<Sensor>();

        value.map(dto => {
            result.push(Sensor.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<Sensor> {
        return this.all;
    }
}

export default SensorStore;