import axios, {AxiosPromise} from "axios";
import LightDto from "./LightDto";

class LightClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    getAll(): AxiosPromise {
        const url = `${this._baseUrl}/intern/light/states/`;
        return axios.get<Array<LightDto>>(url);
    }

    toggleLight(id: number, status: string): AxiosPromise {
        const url = `${this._baseUrl}/intern/light/set/${id}/${status}`;
        return axios.post(url, {})
            .then(response => this.getAll());
    }
}

export default LightClient;