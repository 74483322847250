import React from "react";
import {inject, observer} from "mobx-react";

const Plug = inject("smartHomeUiService")(observer(({plugName, icon, smartHomeUiService}) => {

    const plug = smartHomeUiService.getPlug(plugName) ? smartHomeUiService.getPlug(plugName) : null;
    const status: string = plug && plug.getOn() ? "on" : "off";

    return (
        <div className={plugName + " btn " + status}
             onClick={plug ? () => smartHomeUiService.togglePlug(plug.getId(), plug.getOn()) : null}>
            <i className={"fas fa-" + icon}/>
        </div>
    );

}));

export default Plug;