import UpsertShoppingItemDto from "../UpsertShoppingItemDto";

class UpsertShoppingItemCommand {
    private readonly item: UpsertShoppingItemDto;

    constructor(item: UpsertShoppingItemDto) {
        this.item = item;
    }

    getNewBookmark(): UpsertShoppingItemDto {
        return this.item;
    }
}

export default UpsertShoppingItemCommand;