import React from "react";
import {observer} from "mobx-react";
import moment from "moment-timezone";

const EventRow = (observer(({events, location}) => {

    const eventsLength = events.length;

    const getStartTime = (gw2event) => {
        return gw2event.name !== '' ? moment.utc(gw2event.start, "HH:mm A").local().format("HH:mm") : '';
    };

    const getClassName = (gw2event, index: number) => {
        let className = "event " + gw2event.className;
        if (eventsLength === index + 1) {
            const isDST = moment.utc(gw2event.start, "HH:mm A").local().isDST();
            className = className + " isLastEventItem" + (isDST ? " summer" : " winter");
        }
        return className;
    };

    return (
        <div className="eventRow" data-location={location}>
            {events.map((gw2event, key) => {


                return (
                    <div key={key} className={getClassName(gw2event, key)}
                         style={{width: (gw2event.duration * 5) + 'px'}}>
                        <span>{getStartTime(gw2event)}</span><br/>
                        <span style={{whiteSpace: 'nowrap'}}>{gw2event.name}</span><br/>
                    </div>
                )
            })}
            {events.map((gw2event, key) => {
                let startTime = gw2event.name !== '' ? moment.utc(gw2event.start, "HH:mm A").local().format("HH:mm") : '';
                let className = "event " + gw2event.className
                if (eventsLength === key + 1) {
                    className = className + " isLastEventItem";
                }

                return (
                    <div key={key} className={className}
                         style={{width: (gw2event.duration * 5) + 'px'}}>
                        <span>{startTime}</span><br/>
                        <span style={{whiteSpace: 'nowrap'}}>{gw2event.name}</span><br/>
                    </div>
                )
            })}
        </div>
    )
}));

export default EventRow;