import React from "react";
import {NavLink} from "react-router-dom";
import {observer} from "mobx-react";

class NavigationItemDto {
    target: string;
    icon: string;
}

const NavigationItem = (observer(({target, icon}: NavigationItemDto) => {
    return (
        <div className="col text-center">
            <NavLink exact activeClassName="isActive" className="nav-link" to={target}>
                <i className={icon}></i>
            </NavLink>
        </div>
    );
}));

export default NavigationItem;

