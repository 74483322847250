import React from "react";
import {inject, observer} from "mobx-react";
import Room from "../room/Room";
import Switch from "../switch/Switch";


const Overview = inject("smartHomeUiService")(observer(({smartHomeUiService}) => {

    return (
        <div className="overview">
            <div className="rooms">
                {smartHomeUiService.getRooms().map((room) => {
                    return (<Room roomName={room.name} key={room.getId()}/>);
                })}
            </div>
            <Switch/>
        </div>
    );
}));

export default Overview;