import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {observer} from 'mobx-react';

const ModalContainer = observer(({icon, title, content, className}) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <div>
            <div className="clickDiv" onClick={handleShow}>
                <i className={"fa fa-" + icon}/>
            </div>
            <Modal className={className && className} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    {title}
                </Modal.Header>
                <Modal.Body>
                    {content}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Schließen</Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
});

export default ModalContainer;