import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import AuthClient from "../../api/auth/AuthClient";

class LoginStore {
    @observable
    private username: string;

    @observable
    private password: string;

    private authClient: AuthClient;

    constructor(authClient: AuthClient) {
        this.authClient = authClient;
    }

    getUsername(): string {
        return this.username;
    }

    getPassword(): string {
        return this.password;
    }

    @action
    setUsername(username: string): void {
        this.username = username;
    }

    @action
    setPassword(password: string): void {
        this.password = password;
    }

    login(): AxiosPromise {
        return this.authClient.login(this.username, this.password);
    }

    @action
    clear(): void {
        this.password = "";
        this.username = "";
    }
}

export default LoginStore;