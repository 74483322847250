import MeterOverviewDto from "../../api/meter/MeterOverviewDto";
import MeterConsumption from "./MeterConsumption";

class MeterOverview {
    private _id: number;
    private _readingDate: string;
    private _formattedReadingDate: string;
    private _meterReading: number;
    private _meterReadingCorrection: number;
    private _meterId: number;
    private _meterPosition: number;
    private _meterIcon: string;
    private _roomName: string;
    private _meterStatistic: MeterConsumption;

    constructor(id: number, readingDate: string, formattedReadingDate: string, meterReading: number, meterReadingCorrection: number, meterId: number, meterPosition: number, meterIcon: string, roomName: string, meterStatistic: MeterConsumption) {
        this._id = id;
        this._readingDate = readingDate;
        this._formattedReadingDate = formattedReadingDate;
        this._meterReading = meterReading;
        this._meterReadingCorrection = meterReadingCorrection;
        this._meterId = meterId;
        this._meterPosition = meterPosition;
        this._meterIcon = meterIcon;
        this._roomName = roomName;
        this._meterStatistic = meterStatistic;
    }


    get id(): number {
        return this._id;
    }

    get readingDate(): string {
        return this._readingDate;
    }

    get formattedReadingDate(): string {
        return this._formattedReadingDate;
    }

    get meterReading(): number {
        return this._meterReading;
    }

    get meterReadingCorrection(): number {
        return this._meterReadingCorrection;
    }

    get meterId(): number {
        return this._meterId;
    }

    get meterPosition(): number {
        return this._meterPosition;
    }

    get meterIcon(): string {
        return this._meterIcon;
    }

    get roomName(): string {
        return this._roomName;
    }

    get meterStatistic(): MeterConsumption {
        return this._meterStatistic;
    }

    static createFrom(dto: MeterOverviewDto): MeterOverview {
        const meterStatistic = dto.meterStatistic ? MeterConsumption.createFrom(dto.meterStatistic) : new MeterConsumption(0,0,0);

        return new MeterOverview(dto.id, dto.readingDate, dto.formattedReadingDate, dto.meterReading, dto.meterReadingCorrection, dto.meterId, dto.meterPosition, dto.meterIcon, dto.roomName, meterStatistic)
    }
}

export default MeterOverview;