import axios, {AxiosPromise} from "axios";

class VersionInfoClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    getBackendVersion(): AxiosPromise {
        const url = `${this._baseUrl}/info`;
        return axios.get<string>(url);
    }

    getFrontendVersion(): AxiosPromise {
        return axios.get<string>('application.info');
    }

}

export default VersionInfoClient;