import React from "react";
import {Redirect} from "react-router-dom";
import {inject, observer} from "mobx-react";

const Logout = inject("securityUiService")(observer(({securityUiService}) => {
    securityUiService.logout();

    return (
        <Redirect to="/login"/>
    );
}));

export default Logout;