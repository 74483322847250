import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import EventCollectionDto from "../../api/guildwars/EventCollectionDto";
import Gw2EventCollection from "../../model/guildwars/Gw2EventCollection";
import GuildwarsClient from "../../api/guildwars/GuildwarsClient";


class GuildwarsEventStore {
    @observable
    private all: Array<Gw2EventCollection>;

    private client: GuildwarsClient;

    constructor(client: GuildwarsClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<Gw2EventCollection>();
    }

    fetchAll(): AxiosPromise {
        return this.client.getAll().then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<EventCollectionDto>): void {
        const result = new Array<Gw2EventCollection>();

        value.map(dto => {
            result.push(Gw2EventCollection.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<Gw2EventCollection> {
        return this.all;
    }
}

export default GuildwarsEventStore;