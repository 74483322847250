import React from "react";
import Waste from "./Waste";

const Home = () => {

    var content = [];
    for (var i = 1; i <= 100; i++) {
        content.push("zeile " + i)
    }

    return (
        <div>
            <div className="row">
                {/*<div className="col">*/}
                {/*</div>*/}
                <div className="col-12 col-md-6">
                    <Waste/>
                </div>
            </div>
            <div className="row">
            </div>
        </div>
    )
}

export default Home;
