import React from "react";
import BookmarkForm from "./form/BookmarkForm";
import BookmarkList from "./list/BookmarkList";
import {Container} from "react-bootstrap";
import UpsertBookmark from "../../model/bookmark/UpsertBookmark";


const BookmarkIndex = () => {

    return (
        <Container fluid={true} className="bookmark">
            <BookmarkForm bookmark={new UpsertBookmark()}/>
            <BookmarkList/>
        </Container>
    );
};

export default BookmarkIndex;