import {action, observable} from "mobx";

class UpsertShoppingItem {

    @observable
    private id: number;

    @observable
    private item: string;

    @observable
    private active: boolean;

    constructor() {
        this.init();
    }

    @action
    private init(): void {
        this.id = null;
        this.item = "";
        this.active = null;
    }

    getId(): number {
        return this.id;
    }

    getItem(): string {
        return this.item;
    }

    isActive(): boolean {
        return this.active;
    }

    @action
    setId(id: number): void {
        this.id = id;
    }

    @action
    setItem(item: string): void {
        this.item = item;
    }

    @action
    setActive(active: boolean): void {
        this.active = active;
    }

    handleItemChanged(value: string): void {
        this.setItem(value);
    }

    handleActiveChanged(value: boolean): void {
        this.setActive(value);
    }

    clear(): void {
        this.init();
    }


    // static createFrom(dto: UpsertShoppingItemDto): ShoppingItem {
    //     return new ShoppingItem(dto.id, dto.created, dto.updated, dto.link, dto.description, dto.active)
    // }
}

export default UpsertShoppingItem;