import axios from "axios";
import UserSessionStore from "../../store/security/UserSessionStore";
import NotificationUiService from "../../services/notification/NotificationUiService";

export default class SessionTimoutConfiguration {
    private userSessionStore: UserSessionStore;

    constructor(userSessionStore: UserSessionStore) {
        this.userSessionStore = userSessionStore;
    }

    public configureSessionTimeout(): void {
        axios.interceptors.response.use(
            response => response,
            error => this.handleError(error)
        );
    }

    private handleError(error: any): Promise<any> {
        console.log(error)
        console.log(error.response)
        if (error.response.data.path === "/api/v1/auth") {
            return error;
        }

        if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
            this.userSessionStore.clear();
            NotificationUiService.getInstance().showErrorNotification("Sitzung abgelaufen!", "Sie sind nicht authentifiziert und wurden zur Login-Seite weitergeleitet!");
        }
        return Promise.reject(error);
    }
}