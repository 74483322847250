import RoomDto from "../../api/smartHome/RoomDto";

class Room {
    private id: string;
    private name: string;
    private location: string;


    constructor(id: string, name: string, location: string) {
        this.id = id;
        this.name = name;
        this.location = location;
    }

    getId(): string {
        return this.id;
    }

    getName(): string {
        return this.name;
    }

    getLocation(): string {
        return this.location;
    }

    static createFrom(dto: RoomDto): Room {
        return new Room(dto.id, dto.name, dto.location)
    }
}

export default Room;