import axios, {AxiosPromise} from "axios";
import SensorDto from "./SensorDto";

class SensorClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    getAll(): AxiosPromise {
        const url = `${this._baseUrl}/intern/sensor/states/`;
        return axios.get<Array<SensorDto>>(url);
    }
}

export default SensorClient;