import NotificationUiService from "../notification/NotificationUiService";
import Gw2EventCollection from "../../model/guildwars/Gw2EventCollection";
import GuildwarsEventStore from "../../store/guildwars/GuildwarsEventStore";
import PactMerchantStore from "../../store/guildwars/PactMerchantStore";
import PactMerchant from "../../model/guildwars/PactMerchants";
import PipStore from "../../store/guildwars/PipStore";
import PipDivision from "../../model/guildwars/PipDivision";
import PipStats from "../../model/guildwars/PipStats";
import wood from "../../assets/Holz.png";

class GuildwarsUiService {
    private guildwarsEventStore: GuildwarsEventStore;
    private pactMerchantStore: PactMerchantStore;
    private pipStore: PipStore;

    constructor(guildwarsEventStore: GuildwarsEventStore, pactMerchantStore: PactMerchantStore, pipStore: PipStore) {
        this.guildwarsEventStore = guildwarsEventStore;
        this.pactMerchantStore = pactMerchantStore;
        this.pipStore = pipStore;
    }

    loadAll(): void {
        this.guildwarsEventStore.fetchAll().catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Events konnten nicht geladen werden!");
        });
    }

    loadTodaysPactMerchants(): void {
        this.pactMerchantStore.fetchAll().catch((e) => {
            NotificationUiService.getInstance().showErrorNotification("Fehler!", "Events konnten nicht geladen werden!");
        });
    }

    getAll(): Array<Gw2EventCollection> {
        return this.guildwarsEventStore.getAll();
    }

    getTodaysPactMerchantLinks(): PactMerchant {
        return this.pactMerchantStore.getMerchants();
    }

    copyToClipboard(data): void {
        let dummy = document.createElement("input");
        document.body.appendChild(dummy).setAttribute('value', data);
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);
        NotificationUiService.getInstance().showSuccessNotification("Success", "Links wurden in die Zwischenablage kopiert")
    }

    togglePipCalculation() {
        this.pipStore.changePipCalculationShowStatus();
    }

    getPipCalculationShowStatus() {
        return this.pipStore.getShowPipCalculation();
    }

    selectPipDevision(division: PipDivision) {
        this.pipStore.selectDivision(division);
        this.selectPipDevisionRank(0);
    }

    selectPipDevisionRank(rank: number) {
        this.pipStore.selectDivisionRank(rank);
        this.selectPipDevisionRankPoints(0);
    }

    selectPipDevisionRankPoints(points: number) {
        this.pipStore.selectDivisionRankPoints(points);
        this.calculateStats();
    }

    selectPipsPerTick(pips: number) {
        this.pipStore.selectPipsPerTick(pips);
        this.calculateStats();
    }

    getSelectedPipDevision() {
        return this.pipStore.getSelectedDivision();
    }

    getSelectedPipDevisionRank() {
        return this.pipStore.getSelectedDivisionRank();
    }

    getSelectedPipDevisionRankPoints() {
        return this.pipStore.getSelectedDivisionRankPoints();
    }

    getPipsPerTick() {
        return this.pipStore.getPipsPerTick();
    }

    calculateStats() {
        const totalPips = 1450;
        const minutesPerTick = 5;


        this.pipStore.getSelectedDivision() ? this.pipStore.getSelectedDivision() : this.pipStore.selectDivision(new PipDivision("wood", "holz", 4, 25, 0, wood));

        const selectedDivision = this.pipStore.getSelectedDivision()
        const selectedRankPoints = this.pipStore.getSelectedDivisionRankPoints() ? this.pipStore.getSelectedDivisionRankPoints() : 0;
        const pipsPerTick = this.getPipsPerTick() ? this.getPipsPerTick() : 7;
        const rankPointsDone = this.pipStore.getSelectedDivisionRank() * selectedDivision.getPointsPerRank()

        const remainingPips = totalPips - selectedDivision.getPointsDoneOnCompletition() - rankPointsDone - selectedRankPoints;
        const remainingTicks = Math.ceil(remainingPips / pipsPerTick);
        const remainingTimeMinutes = remainingTicks * minutesPerTick;
        const remainingTimeHours = remainingTimeMinutes / 60;

        const pipStats = new PipStats(remainingPips, remainingTimeMinutes, remainingTimeHours);
        this.pipStore.updateStats(pipStats);
    }

    getCalculatedStats(): PipStats {
        return this.pipStore.getStats();
    }
}

export default GuildwarsUiService;