import axios, {AxiosPromise} from "axios";
import RoomDto from "./RoomDto";

class RoomClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    getAll(): AxiosPromise {
        const url = `${this._baseUrl}/intern/rooms`;
        return axios.get<Array<RoomDto>>(url);
    }

}

export default RoomClient;