import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import ClimateControlDto from "../../api/smartHome/ClimateControlDto";
import ClimateControlClient from "../../api/smartHome/ClimateControlClient";
import ClimateControl from "../../model/smartHome/ClimateControl";

class ClimateControlStore {
    @observable
    private all: Array<ClimateControl>;

    private client: ClimateControlClient;

    constructor(client: ClimateControlClient) {
        this.client = client;
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<ClimateControl>();
    }

    fetchAll(): AxiosPromise {
        return this.client.getAll().then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<ClimateControlDto>): void {
        const result = new Array<ClimateControl>();

        value.map(dto => {
            result.push(ClimateControl.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<ClimateControl> {
        return this.all;
    }

    setTemperature(name: string, temperature: number): AxiosPromise {
        return this.client.setTemperature(name, temperature).then(response => {
            this.setAll(response.data);
            return response;
        });
    }
}

export default ClimateControlStore;