import React from "react";
import {inject, observer} from "mobx-react";


const ClimateControl = inject("smartHomeUiService")(observer(({smartHomeUiService, roomName}) => {

    const ccState = smartHomeUiService.getClimateControlState(roomName);

    const isOff = ccState && ccState.getCurrentSet() <= ccState.getEcoSet();

    const getOperationModeIcon = (ccState) => {
        if (ccState.getOperationMode() === "AUTOMATIC") {
            return "operationMode far fa-clock";
        }

        if (ccState.getCurrentSet() > ccState.getEcoSet()) {
            return "operationMode far fa-hand-paper blink";
        }

        return "operationMode fas fa-hand-paper";
    }

    const renderClimateControl = (ccState) => {
        if (!ccState) {
            return (<div/>);
        }

        return (
            <div className="climateControl">
                <div className={isOff ? "btn cold" : "btn hot"}
                     onClick={() => smartHomeUiService.setTemperature(roomName, isOff ? ccState.getComfortSet() : ccState.getEcoSet())}>
                    <div className="ecoSet">
                        {ccState.getCurrentSet()}<br/>
                        <i className="fas fa-snowflake"/>
                    </div>
                    <div className="comfortSet">
                        <i className="fas fa-fire"/><br/>
                        <i className={getOperationModeIcon(ccState)}/>
                    </div>
                </div>
            </div>
        );
    };


    return renderClimateControl(ccState);

    /**

     return (

     <div className={"ccCol " + roomName}>
     {ccState
                ?
                <div>
                    <div className={ccState.getCurrentSet === 5 ? "icon btn off" : "icon btn"}
                         onClick={() => smartHomeUiService.setTemperature(roomName, 5.0)}>
                        {{ccState.getCurrentSet === 5 ? <i className="fas fa-slash fa-flip-horizontal"></i> : null}}
                    </div>

                    <div className={"minus btn " + min}
                         onClick={ccState.getCurrentSet > 5 ? () => smartHomeUiService.setTemperature(roomName, ccState.getCurrentSet - 0.5) : null}>
                        {ccState.getCurrentSet > 5 ? <i className="fas fa-minus"/> : <i className="fas fa-ban"/>}
                    </div>

                    <div className={"eco btn " + ecoActive}
                         onClick={() => smartHomeUiService.setTemperature(roomName, ccState.getEcoSet)}>
                        {ccState.getEcoSet}
                    </div>

                    <div className="set btn">{ccState.getCurrentSet}</div>

                    <div className={"comfort btn " + comfortActive}
                         onClick={() => smartHomeUiService.setTemperature(name, ccState.getComfortSet)}>
                        {ccState.getComfortSet}
                    </div>

                    <div className={"plus btn " + max}
                         onClick={ccState.getCurrentSet < 30 ? () => smartHomeUiService.setTemperature(name, ccState.getCurrentSet + 0.5) : null}>
                        {ccState.getCurrentSet < 30 ? <i className="fas fa-plus"/> : <i className="fas fa-ban"/>}
                    </div>

                    <div className="mode btn">
                        {ccState.getOperationMode === "AUTOMATIC"
                            ?
                            <i className="fas fa-cog"/>
                            :
                            <i className={ccState.getCurrentSet > ccState.getEcoSet ? "fas fa-hand-paper blink" : "fas fa-hand-paper"}/>
                        }
                    </div>
                </div>
                :
                <div>
                    <div className="icon btn"/>
                    <div className="minus btn"><i className="fas fa-minus"/></div>
                    <div className="eco btn"><i className="fas fa-ban"/></div>
                    <div className="set btn"><i className="fas fa-ban"/></div>
                    <div className="comfort btn"><i className="fas fa-ban"/></div>
                    <div className="plus btn"><i className="fas fa-plus"/></div>
                    <div className="mode btn"><i className="fas fa-ban"/></div>
                </div>
            }
     </div>

     )
     */

}));

export default ClimateControl;