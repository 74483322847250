import _ from "lodash";
import LoginStore from "../../store/security/LoginStore";
import NotificationUiService from "../notification/NotificationUiService";
import UserSessionStore from "../../store/security/UserSessionStore";

class LoginUiService {
    private loginStore: LoginStore;
    private notificationUiService: NotificationUiService;
    private userSessionStore: UserSessionStore;

    constructor(loginStore: LoginStore, userSessionStore: UserSessionStore) {
        this.loginStore = loginStore;
        this.notificationUiService = NotificationUiService.getInstance();
        this.userSessionStore = userSessionStore;
    }

    passwordChanged(e: any): void {
        this.loginStore.setPassword(e.target.value);
    }

    usernameChanged(e: any): void {
        this.loginStore.setUsername(e.target.value);
    }

    handleKeyPress(e: any): void {
        if (e.charCode === 13) {
            this.loginButtonClicked();
        }
    }

    loginButtonClicked(): void {
        if (_.isEmpty(this.loginStore.getUsername()) || _.isEmpty(this.loginStore.getPassword())) {
            return;
        }

        this.loginStore.login()
            .then(response => {
                this.userSessionStore.saveInSession(response.data.token, response.data.refreshToken);
                this.loginStore.clear();
                return response;
            })
            .catch(() => {
                this.notificationUiService.showErrorNotification("Fehler", "Login fehlgeschlagen!");
            });
    }

    isAuthenticated(): boolean {
        return this.userSessionStore.isAuthenticated();
    }
}

export default LoginUiService;