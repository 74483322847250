import React from "react";
import Device from "./Device/Device";
import Plug from "./Plug/Plug";
import Livestream from "./Webcam/Livestream";


const Switch = () => {

    return (
        <div className="switches">
            <Device deviceName="desktop-hobggb2" icon="desktop"/>
            <Device deviceName="pc01" icon="desktop"/>
            <Device deviceName="nas" icon="server"/>
            <Plug plugName="lüfter" icon="plug"/>
            <Plug plugName="osramplug1" icon="plug"/>
            <Plug plugName="osramplug2" icon="plug"/>
            <Livestream deviceName="cam1" icon="eye"/>
        </div>
    );


};

export default Switch;