import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import SnippetItem from "./SnippetItem";
import {Row} from "react-bootstrap";


const SnippetList = inject("snippetUiService")(observer(({snippetUiService}) => {
    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                snippetUiService.loadAll()
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });
        },
        [
            snippetUiService
        ]);

    return (
        <Row className="snippetList">
            {snippetUiService.getFiltered(snippetUiService.getFilterValue()).map(snippet => {
                return (
                    <SnippetItem key={snippet.getId()} snippet={snippet}/>
                )
            })}
        </Row>
    );

}));

export default SnippetList;