import MeterTotalConsumptionDto from "../../api/meter/MeterTotalConsumptionDto";

class MeterTotalConsumption {
    private _meterId: number;
    private _meterPosition: number;
    private _differenceReading: number;
    private _differenceDays: number;
    private _averagePerDay: number;

    constructor(meterId: number, meterPosition: number, differenceReading: number, differenceDays: number, averagePerDay: number) {
        this._meterId = meterId;
        this._meterPosition = meterPosition;
        this._differenceReading = differenceReading;
        this._differenceDays = differenceDays;
        this._averagePerDay = averagePerDay;
    }


    get meterId(): number {
        return this._meterId;
    }

    get meterPosition(): number {
        return this._meterPosition;
    }

    get differenceReading(): any {
        return this._differenceReading;
    }

    get differenceDays(): number {
        return this._differenceDays;
    }

    get averagePerDay(): number {
        return this._averagePerDay;
    }

    static createFrom(dto: MeterTotalConsumptionDto): MeterTotalConsumption {
        return new MeterTotalConsumption(dto.meterId, dto.meterPosition, dto.differenceReading, dto.differenceDays, dto.averagePerDay)
    }
}

export default MeterTotalConsumption;



