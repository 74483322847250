import axios, {AxiosPromise} from "axios";
import ShoppingItemDto from "./ShoppingItemDto";
import UpsertShoppingItemCommand from "./data/UpsertShoppingItemCommand";

class ShoppingItemClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    getAll(activeItems: boolean): AxiosPromise {
        const items = activeItems ? "active" : "done"
        const url = `${this._baseUrl}/intern/shoppingitems/${items}`;
        return axios.get<Array<ShoppingItemDto>>(url);
    }

    saveNewShoppingItem(command: UpsertShoppingItemCommand): AxiosPromise {
        const url = `${this._baseUrl}/intern/shoppingitem`;
        return axios.post<any>(url, command);
    }

    changeShoppingItemStatus(command: UpsertShoppingItemCommand): AxiosPromise {
        const url = `${this._baseUrl}/intern/shoppingitem/change-status`;
        return axios.post<any>(url, command);
    }
}

export default ShoppingItemClient;