import axios, {AxiosPromise} from "axios";
import EventCollectionDto from "./EventCollectionDto";
import PactMerchantDto from "./PactMerchantDto";

class GuildwarsClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    getAll(): AxiosPromise {
        const url = `${this._baseUrl}/intern/guildwars/events`;
        return axios.get<Array<EventCollectionDto>>(url);
    }

    getTodaysPactMerchantLinks(): AxiosPromise {
        const url = `${this._baseUrl}/intern/guildwars/pact-merchants`;
        return axios.get<PactMerchantDto>(url);
    }
}

export default GuildwarsClient;