import React from "react";
import {inject, observer} from "mobx-react";


const ConsumptionOverview = inject("meterUiService")(observer(({meterUiService}) => {

    const formatNumber = (number, decimals) => {
        return number.toFixed(decimals).replace(/[.,]000/, "");
    }


    return (
        <div className="meterReadingList">
            <div className="row">
                <div className="col-3 col-md-1 col-room-meter">
                    <div className="cell">
                        Zähler
                    </div>
                    {meterUiService.getOverview() && meterUiService.getOverview().meterOverviewList[0].map(meter => {
                        return (
                            <div className="cell meterCell" key={meter.id}>
                                <div className={meter.roomName + " roomIconCell"}>&nbsp;</div>
                                <div className="meterIconCell"><i className={meter.meterIcon}/></div>
                            </div>
                        );
                    })}
                </div>
                <div className="col-9 col-md-11 col-room-consumption">
                    <div className="consumption-row">
                        <div className="cell difference-reading">
                            Gesamt
                        </div>
                        <div className="cell average-day">
                            pro Tag
                        </div>
                    </div>
                    {/*<div className="listContainer">*/}
                        {meterUiService.getOverview() && meterUiService.getOverview().meterTotalConsumptions.sort((a, b) => a.meterPosition - b.meterPosition).map((meter, key) => {
                            return (
                                <div key={meter.id} className="consumption-row">
                                    <div className="cell difference-reading">
                                        {formatNumber(meter.differenceReading, 3)}
                                    </div>
                                    <div className="cell average-day">
                                        {formatNumber(meter.averagePerDay, 5)}
                                    </div>
                                </div>
                            );
                        })}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );


}));

export default ConsumptionOverview;