import React, {useEffect} from "react";
import {inject, observer} from "mobx-react";
import ShoppingItemItem from "./ShoppingItemItem";


const ShoppingItemList = inject("shoppingItemUiService")(observer(({shoppingItemUiService}) => {
    useEffect(() => {
            const fetchData = async (): Promise<any> => {
                shoppingItemUiService.loadAll()
            };
            fetchData()
                .then(r => r)
                .catch(error => {
                    throw error;
                });
        },
        [
            shoppingItemUiService
        ]);

    return (
        <div className="shoppingItemList">
            {shoppingItemUiService.getAll().map(shoppingItem => {
                return (
                    <ShoppingItemItem key={shoppingItem.getId()} shoppingItem={shoppingItem}/>
                )
            })}
        </div>
    );

}));

export default ShoppingItemList;