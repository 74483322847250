import MeterTotalOverviewDto from "../../api/meter/MeterTotalOverviewReadingDto";
import MeterOverview from "./MeterOverview";
import MeterTotalConsumption from "./MeterTotalConsumption";
import MeterReading from "./MeterReading";

class MeterTotalOverview {
    private _meterTotalConsumptions: Array<MeterTotalConsumption>;
    private _meterOverviewList: Array<MeterOverview>;

    constructor(meterTotalConsumptions: Array<MeterTotalConsumption>, meterOverviewList: Array<any>) {
        this._meterTotalConsumptions = meterTotalConsumptions;
        this._meterOverviewList = meterOverviewList;
    }


    get meterTotalConsumptions(): Array<MeterTotalConsumption> {
        return this._meterTotalConsumptions;
    }

    get meterOverviewList(): Array<MeterOverview> {
        return this._meterOverviewList;
    }

    static createFrom(dto: MeterTotalOverviewDto): MeterTotalOverview {
        const meterTotalConsumptions = Object.values(dto.meterTotalConsumptions).map(item => {
            return MeterTotalConsumption.createFrom(item)
        })

        let meterOverviewList = Object.values(dto.meterOverviewList).map(items => {
            return Object.values(items).map(item => {
                return MeterOverview.createFrom(item);
            })

        })

        return new MeterTotalOverview(meterTotalConsumptions, meterOverviewList)
    }
}

export default MeterTotalOverview;