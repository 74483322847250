import axios, {AxiosPromise} from "axios";
import MeterReadingDto from "./MeterReadingDto";
import MeterReading from "../../model/meter/MeterReading";
import UpsertMeterReadingsCommand from "./data/UpsertMeterReadingsCommand";

class MeterClient {
    private readonly _baseUrl: string;

    constructor(baseUrl: string) {
        this._baseUrl = baseUrl;
    }

    getAll(date: string): AxiosPromise {
        const url = `${this._baseUrl}/intern/meter/${date}`;
        return axios.get<Array<MeterReadingDto>>(url);
    }

    getOverview(startDate: string, endDate: string): AxiosPromise {
        const url = `${this._baseUrl}/intern/meter/statistic/list?startDate=${startDate}&endDate=${endDate}`;
        return axios.get<Array<MeterReadingDto>>(url);
    }

    saveReadings(command: UpsertMeterReadingsCommand): AxiosPromise {
        const url = `${this._baseUrl}/intern/meter/save`;
        return axios.post<any>(url, command);
    }

}

export default MeterClient;