import MeterReadingDto from "../../api/meter/MeterReadingDto";
import Room from "../smartHome/Room";

class MeterReading {
    private _id: number;
    private _readingDate: string;
    private _meterReading: number;
    private _meterReadingCorrection: number;
    private _meterId: string;
    private _meterName: string;
    private _meterType: string;
    private _meterNumber: string;
    private _meterIcon: string;
    private _roomName: string;
    private _roomLocation: string;

    constructor(id: number, readingDate: string, meterReading: number, meterReadingCorrection: number, meterId: string, meterName: string, meterNumber: string, meterIcon: string, roomName: string, roomLocation: string, meterType: string) {
        this._id = id;
        this._readingDate = readingDate;
        this._meterReading = meterReading;
        this._meterReadingCorrection = meterReadingCorrection;
        this._meterId = meterId;
        this._meterName = meterName;
        this._meterNumber = meterNumber;
        this._meterIcon = meterIcon;
        this._roomName = roomName;
        this._roomLocation = roomLocation;
        this._meterType = meterType;
    }


    get id(): number {
        return this._id;
    }

    get readingDate(): string {
        return this._readingDate;
    }

    set readingDate(value) {
        this._readingDate = value;
    }

    get meterReading(): number {
        return this._meterReading;
    }

    set meterReading(value) {
        this._meterReading = value;
    }

    get meterReadingCorrection(): number {
        return this._meterReadingCorrection;
    }

    set meterReadingCorrection(value: number) {
        this._meterReadingCorrection = value;
    }

    get meterId(): string {
        return this._meterId;
    }

    get meterName(): string {
        return this._meterName;
    }

    get meterType(): string {
        return this._meterType;
    }

    get meterNumber(): string {
        return this._meterNumber;
    }

    get meterIcon(): string {
        return this._meterIcon;
    }

    get roomName(): string {
        return this._roomName;
    }

    get roomLocation(): string {
        return this._roomLocation;
    }

    static createFrom(dto: MeterReadingDto): MeterReading {
        return new MeterReading(dto.id, dto.readingDate, dto.meterReading, dto.meterReadingCorrection, dto.meterId, dto.meterName, dto.meterNumber, dto.meterIcon, dto.roomName, dto.roomLocation, dto.meterType)
    }
}

export default MeterReading;