import UpsertSnippetDto from "../UpsertSnippetDto";

class UpsertSnippetCommand {
    private readonly snippet: UpsertSnippetDto;

    constructor(snippet: UpsertSnippetDto) {
        this.snippet = snippet;
    }

    getNewSnippet(): UpsertSnippetDto {
        return this.snippet;
    }
}

export default UpsertSnippetCommand;