import React from "react";
import {inject, observer} from "mobx-react";
import {Row, Col} from "react-bootstrap";


const ShoppingItemItem = inject("shoppingItemUiService")(observer(({shoppingItemUiService, shoppingItem}) => {

    return (
        <Row className="shoppingItem">
            <Col className="item">
                {shoppingItem.item}
            </Col>
            <Col sm={1} className="status text-center"
                 onClick={() => shoppingItemUiService.changeShoppingItemStatus(shoppingItem.getId())}>
                <i className={shoppingItem.isActive() ? 'far fa-check-circle' : 'fas fa-undo'}/>
            </Col>
        </Row>
    );

}));

export default ShoppingItemItem;