import React from "react";
import {inject, observer} from "mobx-react";


const TotalOverview = inject("meterUiService")(observer(({meterUiService}) => {

    const formatNumber = number => {
        return number.toFixed(3).replace(/[.,]000/, "");
    }

    return (
        <div className="meterReadingList">
            <div className="row">
                <div className="col-3 col-md-1 col-room-meter">
                    <div className="cell">Zähler</div>
                    {meterUiService.getOverview() && meterUiService.getOverview().meterOverviewList[0].map(meter => {
                        return (
                            <div className="cell meterCell" key={meter.id}>
                                <div className={meter.roomName + " roomIconCell"}>&nbsp;</div>
                                <div className="meterIconCell"><i className={meter.meterIcon}/></div>
                            </div>
                        );
                    })}
                    <div>&nbsp;</div>
                    {meterUiService.getOverview() && meterUiService.getOverview().meterOverviewList[0].map(meter => {
                        return (
                            <div className="cell meterCell" key={meter.id}>
                                <div className={meter.roomName + " roomIconCell"}>&nbsp;</div>
                                <div className="meterIconCell"><i className={meter.meterIcon}/></div>
                            </div>
                        );
                    })}

                </div>
                <div className="col-9 col-md-11 col-room-reading">
                    <div className="listContainer">
                        {meterUiService.getOverview() && meterUiService.getOverview().meterOverviewList.map((month, key) => {
                            return (
                                <div className="monthCol" key={key}>
                                    <div className="cell">{month[0].formattedReadingDate}</div>
                                    {month.map(meter => {
                                        return (
                                            <div className="cell" key={meter.id}>
                                                {meter.meterReading}{meter.meterReadingCorrection && " (" + meter.meterReadingCorrection + ")"}
                                            </div>
                                        );
                                    })}
                                    <div>&nbsp;</div>
                                    {month.map(meter => {
                                        return (
                                            <div key={meter.id} className="cell">
                                                {formatNumber(meter.meterStatistic.differenceReading)}
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );


}));

export default TotalOverview;