import {action, observable} from "mobx";
import {AxiosPromise} from "axios";
import NetworkDeviceDto from "../../api/smartHome/NetworkDeviceDto";
import NetworkDeviceClient from "../../api/smartHome/NetworkDeviceClient";
import NetworkDevice from "../../model/smartHome/NetworkDevice";

class NetworkDeviceStore {
    @observable
    private all: Array<NetworkDevice>;

    @observable
    private stream: string;

    private client: NetworkDeviceClient;

    constructor(client: NetworkDeviceClient) {
        this.client = client;
        this.stream = "hide";
        this.init();
    }

    @action
    private init(): void {
        this.all = new Array<NetworkDevice>();
    }

    fetchAll(): AxiosPromise {
        return this.client.getAll().then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    @action
    private setAll(value: Array<NetworkDeviceDto>): void {
        const result = new Array<NetworkDevice>();

        value.map(dto => {
            result.push(NetworkDevice.createFrom(dto));
            return dto;
        });

        this.all = result;
    }

    getAll(): Array<NetworkDevice> {
        return this.all;
    }

    // toggleDevice(id: number): void {
    //     this.client.toggleDevice(id);
    // }

    toggleStatus(ip: string, mac: string, status: string): AxiosPromise {
        return this.client.toggleStatus(ip, mac, status).then(response => {
            this.setAll(response.data);
            return response;
        });
    }

    toggle(): void {
        this.stream = this.stream === "show" ? "hide" : "show";
    }

    getStatus(): string {
        return this.stream;
    }
}

export default NetworkDeviceStore;