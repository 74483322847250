import Notification from "../../model/notification/Notification";
import NotificationService from "./NotificationService";
import NotificationStore from "../../store/notification/NotificationStore";

class NotificationUiService {
    private static instance: NotificationUiService;
    private notificationService: NotificationService;

    private constructor(notificationService: NotificationService) {
        this.notificationService = notificationService;
    }

    get notifications(): Notification[] {
        return this.notificationService.notifications;
    }

    static getInstance(): NotificationUiService {
        if (!NotificationUiService.instance) {
            NotificationUiService.instance = new NotificationUiService(new NotificationService(new NotificationStore()));
        }
        return NotificationUiService.instance;
    }

    onAlertDismissed(notification: Notification): void {
        this.notificationService.removeNotification(notification);
    }

    public showSuccessNotification(headline: string, message: string): void {
        this.notificationService.showSuccessNotification(headline, message);
    }

    public showInfoNotification(headline: string, message: string): void {
        this.notificationService.showInfoNotification(headline, message);
    }

    public showErrorNotification(headline: string, message: string): void {
        this.notificationService.showErrorNotification(headline, message);
    }
}

export default NotificationUiService;