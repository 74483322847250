import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Provider} from "mobx-react";
import Navigation from "./navigation/Navigation";
import Routes from "../api/Routes";
import SmartHome from "./smartHome/SmartHomeIndex";
import Meter from "./meter/MeterIndex";
import Home from "./home/Home";
import SmartHomeUiService from "../services/smartHome/smartHomeUiService";
import Config from "../Config";
import SensorClient from "../api/smartHome/SensorClient";
import SensorStore from "../store/smartHome/SensorStore";
import LightClient from "../api/smartHome/LightClient";
import LightStore from "../store/smartHome/LightStore";
import BookmarkClient from "../api/bookmark/BookmarkClient";
import BookmarkStore from "../store/bookmark/BookmarkStore";
import BookmarkUiService from "../services/bookmark/bookmarkUiService";
import Bookmark from "./bookmark/BookmarkIndex";
import ClimateControlClient from "../api/smartHome/ClimateControlClient";
import ClimateControlStore from "../store/smartHome/ClimateControlStore";
import NetworkDeviceClient from "../api/smartHome/NetworkDeviceClient";
import NetworkDeviceStore from "../store/smartHome/NetworkDeviceStore";
import Shopping from "./shopping/ShoppingIndex";
import ShoppingItemClient from "../api/shopping/ShoppingItemClient";
import ShoppingItemStore from "../store/shopping/ShoppingItemStore";
import ShoppingItemUiService from "../services/shopping/shoppingItemUiService";
import SmartHomeStore from "../store/smartHome/SmartHomeStore";
import RoomStore from "../store/smartHome/RoomStore";
import RoomClient from "../api/smartHome/RoomClient";
import PlugClient from "../api/smartHome/PlugClient";
import PlugStore from "../store/smartHome/PlugStore";
import Snippet from "./snippet/SnippetIndex";
import SnippetStore from "../store/snippet/SnippetStore";
import SnippetClient from "../api/snippet/SnippetClient";
import SnippetUiService from "../services/snippet/snippetUiService";
import Gw2 from "./gw2/Gw2Index";
import GuildwarsEventStore from "../store/guildwars/GuildwarsEventStore";
import GuildwarsClient from "../api/guildwars/GuildwarsClient";
import GuildwarsUiService from "../services/guildwars/GuildwarsUiService";
import PactMerchantStore from "../store/guildwars/PactMerchantStore";
import VersionInfoClient from "../api/versioninfo/VersionInfoClient";
import VersionInfoStore from "../store/versioninfo/VersionInfoStore";
import VersionInfoUiService from "../services/versioninfo/VersionInfoUiService";
import VersionInfo from "./versioninfo/VersionInfo";
import PipStore from "../store/guildwars/PipStore";
import Logout from "./security/Logout";
import MeterClient from "../api/meter/MeterClient";
import MeterStore from "../store/meter/MeterStore";
import MeterUiService from "../services/meter/meterUiService";


const roomStore = new RoomStore(new RoomClient(Config.BACKEND_API));
const smartHomeStore = new SmartHomeStore();
const sensorStore = new SensorStore(new SensorClient(Config.BACKEND_API));
const lightStore = new LightStore(new LightClient(Config.BACKEND_API));
const plugStore = new PlugStore(new PlugClient(Config.BACKEND_API));
const networkDeviceStore = new NetworkDeviceStore(new NetworkDeviceClient(Config.BACKEND_API, Config.BACKEND_HOME_API));
const climateControlStore = new ClimateControlStore(new ClimateControlClient(Config.BACKEND_API, Config.BACKEND_HOME_API));
const smartHomeUiService = new SmartHomeUiService(roomStore, smartHomeStore, sensorStore, lightStore, plugStore, climateControlStore, networkDeviceStore);

const bookmarkStore = new BookmarkStore(new BookmarkClient(Config.BACKEND_API));
const bookmarkUiService = new BookmarkUiService(bookmarkStore);

const guildwarsEventStore = new GuildwarsEventStore(new GuildwarsClient(Config.BACKEND_API));
const pactMerchantStore = new PactMerchantStore(new GuildwarsClient(Config.BACKEND_API));
const pipStore = new PipStore();
const guildwarsUiService = new GuildwarsUiService(guildwarsEventStore, pactMerchantStore, pipStore);

const versionInfoStore = new VersionInfoStore(new VersionInfoClient(Config.INFO_API));
const versionInfoUiService = new VersionInfoUiService(versionInfoStore);

const snippetStore = new SnippetStore(new SnippetClient(Config.BACKEND_API));
const snippetUiService = new SnippetUiService(snippetStore);

const shoppingItemStore = new ShoppingItemStore(new ShoppingItemClient(Config.BACKEND_API));
const shoppingItemUiService = new ShoppingItemUiService(shoppingItemStore);

const meterStore = new MeterStore(new MeterClient(Config.BACKEND_API));
const meterUiService = new MeterUiService(meterStore);


const stores = {
    smartHomeUiService,
    meterUiService,
    bookmarkUiService,
    shoppingItemUiService,
    snippetUiService,
    guildwarsUiService,
    versionInfoUiService
};

const App = () => (
    <React.Fragment>
        <Provider {...stores}>
            <BrowserRouter>
                <Navigation/>
                <main>
                    <div className="content-container container-fluid bg-dark text-light">
                        <Switch>
                            <Route path={Routes.INDEX} exact component={Home}/>
                            <Route path={Routes.SMARTHOME} exact component={SmartHome}/>
                            <Route path={Routes.METER} exact component={Meter}/>
                            <Route path={Routes.BOOKMARK} exact component={Bookmark}/>
                            <Route path={Routes.SHOPPING} exact component={Shopping}/>
                            <Route path={Routes.SNIPPET} exact component={Snippet}/>
                            <Route path={Routes.GW2} exact component={Gw2}/>
                            <Route path={Routes.LOGOUT} exact component={Logout}/>
                        </Switch>

                    </div>
                </main>
            </BrowserRouter>
            <VersionInfo/>
        </Provider>

    </React.Fragment>
);

export default App;
