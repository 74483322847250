import React from "react";
import {inject, observer} from "mobx-react";

const Light = inject("smartHomeUiService")(observer(({roomName, smartHomeUiService}) => {

    const lights = smartHomeUiService.getLights(roomName) ? smartHomeUiService.getLights(roomName) : null;

    const renderLights = (lights, roomName) => {
        if (!lights || lights.length === 0) {
            return (
                <div className="lights">
                    <div className={roomName + " light off"}/>
                </div>
            );
        }

        return (
            <div className="lights">
                {lights.map((light) => {
                    return (
                        <div className={light.getName() + " light " + (light.getOn() ? "on" : "off")}
                             onClick={() => smartHomeUiService.toggleLight(light.getId(), light.getOn())}
                             key={light.getId()}>
                        </div>
                    );
                })}
            </div>
        );

    };

    return renderLights(lights, roomName);

}));

export default Light;