import React from "react";
import Form from "react-bootstrap/Form";
import {FormControl} from "react-bootstrap";
import {inject, observer} from "mobx-react";
import Button from "react-bootstrap/Button";
import moment from "moment";

const GatherMeterReadings = inject("meterUiService")(observer(({meterUiService}) => {

    const disabled = moment(meterUiService.getSelectedDate()).isBefore(moment(new Date()).format("YYYY-MM-DD"), 'day')

    return (
        <div className="row">
            <div className="col-12 col-md-6">
                <Form className="meterReadingForm">

                    <div className="row">
                        <div className="col-4">Ablesedatum</div>
                        <div className="col-8">
                            <Form.Control type="date"
                                          name="readingdate"
                                          placeholder=""
                                          onChange={(e: any) => meterUiService.changeDate(e.target.value)}
                                          value={meterUiService.getSelectedDate()}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-4"></div>
                        <div className="col-4">Abgelesen</div>
                        <div className="col-4">Korrigiert</div>
                    </div>

                    {meterUiService.getAll().map(meter => {
                        return (
                            <div className="row" key={meter.meterId}>
                                <div className={"col-2 " + meter.roomName}></div>

                                <div className="col-2">
                                    <i className={meter.meterIcon}/>
                                </div>
                                <div className="col-4">
                                    <FormControl
                                        type={"number"}
                                        disabled={disabled}
                                        placeholder="0000,000"
                                        onChange={(e: any) => meterUiService.update(meter.meterId, e.target.value)}
                                        value={meter.meterReading ? meter.meterReading : ""}
                                    />
                                </div>
                                <div className="col-4">
                                    <FormControl
                                        type={"number"}
                                        disabled={disabled}
                                        placeholder="0000,000"
                                        onChange={(e: any) => meterUiService.updateCorrection(meter.meterId, e.target.value)}
                                        value={meter.meterReadingCorrection ? meter.meterReadingCorrection : ""}
                                    />
                                </div>
                            </div>
                        );
                    })}

                    <div className="row">
                        <div className="col-12">
                            <Button type="submit" variant="primary" className="btn-block" disabled={disabled}
                                    onClick={(e) => meterUiService.saveReadings(e)}>
                                <i className="fas fa-save"/>
                            </Button>
                        </div>
                    </div>

                </Form>
            </div>
        </div>

    )
}));

export default GatherMeterReadings;
