import axios, {AxiosPromise} from "axios";
import ClientJS from "clientjs";

class AuthClient {
    private readonly authUrl: string;
    private readonly client: any;

    constructor(authUrl: string) {
        this.authUrl = authUrl;
        this.client = new ClientJS();
    }

    hashCode(s: string): number {
        let i;
        let h;
        for (i = 0, h = 0; i < s.length; i++)
            h = Math.imul(31, h) + s.charCodeAt(i) | 0;
        return h;
    }

    login(username: string, password: string): AxiosPromise {
        console.log(this.authUrl)
        const result = this.client.getResult();
        const fingerprint = this.hashCode(JSON.stringify(result));
        localStorage.setItem('id', fingerprint.toString());
        const url = `${this.authUrl}/login`;
        return axios.post(url, {
            username,
            password,
            fingerprint
        });
    }

    validate(token: string): AxiosPromise {
        const url = `${this.authUrl}/validate`;
        const fingerprint = localStorage.getItem('id');

        return axios.post(url, {token, fingerprint});
    }
}

export default AuthClient;