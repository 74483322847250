import React, {useState} from "react";
import SnippetFilter from "../form/SnippetFilter";
import SnippetForm from "../form/SnippetForm";
import UpsertSnippet from "../../../model/snippet/UpsertSnippet";


const SnippetHeader = () => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const renderFilter = () => {
        return (
            <SnippetFilter handleShow={handleShow}/>
        );
    };

    const renderNewSnippetForm = () => {
        return (
            <SnippetForm handleClose={handleClose} snippet={new UpsertSnippet()}/>
        );
    };

    return show ? renderNewSnippetForm() : renderFilter();
};

export default SnippetHeader;