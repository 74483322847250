import ShoppingItemDto from "../../api/shopping/ShoppingItemDto";

class ShoppingItem {
    private id: number;
    private created: string;
    private updated: string;
    private item: string;
    private active: boolean;

    constructor(id: number, created: string, updated: string, item: string, active: boolean) {
        this.id = id;
        this.created = created;
        this.updated = updated;
        this.item = item;
        this.active = active;
    }

    getId(): number {
        return this.id;
    }

    getCreated(): string {
        return this.created;
    }

    getUpdated(): string {
        return this.updated;
    }

    getItem(): string {
        return this.item;
    }

    isActive(): boolean {
        return this.active;
    }


    static createFrom(dto: ShoppingItemDto): ShoppingItem {
        return new ShoppingItem(dto.id, dto.created, dto.updated, dto.item, dto.active)
    }
}

export default ShoppingItem;