import React from "react";
import {observer} from "mobx-react";
import Light from "./Light";
import Sensor from "./Sensor";
import ClimateControl from "./ClimateControl";

interface RoomProps {
    roomName: string;
}

const Room = (observer(({roomName}: RoomProps) => {
    return (
        <div className={"room " + roomName}>
            <Light roomName={roomName}/>
            <Sensor roomName={roomName}/>
            <ClimateControl roomName={roomName}/>
        </div>
    );
}));

export default Room;