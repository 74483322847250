import React from "react";
import {Row, Col} from "react-bootstrap";
import {inject, observer} from "mobx-react";


const ShoppingItemListChanger = inject("shoppingItemUiService")(observer(({shoppingItemUiService}) => {

    return (
        <Row className="changeListViewItems">
            <Col onClick={() => shoppingItemUiService.changeListView()} className="text-center">
                {shoppingItemUiService.getListView()
                    ?
                    <i className="fas fa-clipboard-check"/>
                    :
                    <i className="fas fa-shopping-cart"/>
                }
            </Col>
        </Row>
    );
}));

export default ShoppingItemListChanger;