class PipDivision {
    private division: string;
    private name: string;
    private ranks: number;
    private pointsPerRank: number;
    private pointsDoneOnCompletition: number;
    private icon: any;

    constructor(division: string, name: string, ranks: number, pointsPerRank: number, pointsDoneOnCompletition: number, icon: any) {
        this.division = division;
        this.name = name;
        this.ranks = ranks;
        this.pointsPerRank = pointsPerRank;
        this.pointsDoneOnCompletition = pointsDoneOnCompletition;
        this.icon = icon;
    }

    getDivision(): string {
        return this.division;
    }

    getName(): string {
        return this.name;
    }

    getRanks(): number {
        return this.ranks;
    }

    getPointsPerRank(): number {
        return this.pointsPerRank;
    }

    getPointsDoneOnCompletition(): number {
        return this.pointsDoneOnCompletition;
    }

    getIcon(): any {
        return this.icon;
    }
}

export default PipDivision;
