import React from "react";
import {Redirect} from "react-router-dom";
import {inject, observer} from "mobx-react";
import App from "../App";
import Routes from "../../api/Routes";

const SecuredComponent = inject("securityUiService")(observer(({securityUiService}) => {
    const renderComponent = () => {
        if (securityUiService.isLoggedIn()) {
            return (
                <App/>
            );
        }

        return (
            <Redirect to={Routes.LOGIN}/>
        );
    };

    return (
        <div className={"secured"}>
            {renderComponent()}
        </div>
    );
}));

export default SecuredComponent;